import client from '../helpers/authenticated-axios'

export const fileSystemService = {

  fileSystemLoad(callback) {
    client().get('/filesystems').then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },

  /**
   * Creates a new filesystem API
   *
   * @param {String} name
   * @param {any} config
   * @returns {Promise<*>}
   */
  async addFilesystem(name, config) {
    return client().post('/filesystems', {
      'identifier': name, 'config': JSON.parse(config)
    })
  },

  /**
   * Updates a filesystem API
   *
   * @param {String} name
   * @param {String} newName
   * @param {any} config
   * @returns {Promise<*>}
   */
  async editFilesystem(name, newName, config) {
    return client().put('/filesystems' + '/' + name, {
      'identifier': newName ?? name,
      'config': JSON.parse(config)
    })
  },

  deleteFilesystem(filesystem, callback) {
    client().delete(filesystem).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  }

};
