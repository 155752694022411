<script>
import { SEPARATOR } from 'pages/JsonToForm/utils'
import { cloneDeep } from 'lodash'
import SqMarkdownDisplay from 'components/Common/SqMarkdownDisplay.vue'

export default {
  name: 'ConfigSearch',
  components: { SqMarkdownDisplay },

  props: {
    uiSchemas: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  emits: ['search'],

  data() {
    return {
      selectedElement: null,
      selectableResults: []
    }
  },

  computed: {
    selectableOptions() {
      return this.flattenTabElements(this.uiSchemas)
    }
  },

  methods: {
    flattenTabElements(tabs) {
      const elements = []

      for (const tab of tabs) {
        elements.push(...this.flattenElements(tab.elements, tab))
      }

      return elements
    },

    flattenElements(elements, tab, baseName) {
      const uiElements = []

      for (const element of elements) {
        const copiedElement = { ...element }
        copiedElement.label = baseName ? `${baseName} > ${copiedElement.fieldOptions.props.label}` : copiedElement.fieldOptions.props.label
        copiedElement.key = copiedElement.id.split(SEPARATOR).at(-1)
        copiedElement.tab = tab
        const children = [ ...element.children ]
        if (children.length) {
          uiElements.push(...this.flattenElements(children, tab, copiedElement.label))
        }

        delete copiedElement.children
        uiElements.push(copiedElement)
      }

      return uiElements
    },

    handleFilter(val, update) {
      if (!val) {
        update(() => this.selectableResults = [])
        return
      }

      update(() => {
        const query = val.toLowerCase()
        const options = cloneDeep(this.selectableOptions)
        this.selectableResults = options.filter((element) => {
          const hasLabel = element.fieldOptions.props.label?.toLowerCase()?.indexOf(query) > -1
          const hasHint = element.fieldOptions.props.description?.toLowerCase()?.indexOf(query) > -1
          const hasKey = element.id.split(SEPARATOR).at(-1)?.indexOf(query) > -1
          const inKeys = element.searchKeys?.some(sk => sk.toLowerCase().indexOf(query) > -1)

          return hasLabel || hasHint || hasKey || inKeys
        })
      })
    },

    handleChange() {
      this.$emit('search', this.selectedElement)
    }
  }
}
</script>

<template>
  <q-select
    ref="searchRef"
    v-model="selectedElement"
    use-input
    dense outlined
    clearable
    square
    filled
    virtual-scroll-slice-size="15"
    virtual-scroll-item-size="10"
    input-debounce="500"
    label="Config Search"
    :options="selectableResults"
    behavior="menu"
    @filter="handleFilter"
    @update:model-value="handleChange"
  >
    <template #selected>
      {{ selectedElement?.label }}
    </template>
    <template #option="{ opt, itemProps }">
      <q-item
        dense
        v-bind="itemProps"
        :style="`max-width: ${$refs.searchRef.$el.clientWidth}px;`"
      >
        <q-item-section>
          <div class="text-weight-bolder">
            {{ opt.label }}
          </div>
          <div class="text-caption">
            <div>
              <span class="text-weight-bold">Key</span>:
              {{ opt.key }}
            </div>

            <div>
              <span class="text-weight-bold">Tab</span>:
              {{ opt.tab.label }}
            </div>

            <span
              v-if="opt.fieldOptions.props.description"
              class="text-grey-7"
            >
             <sq-markdown-display :markdown="opt.fieldOptions.props.description" />
            </span>
          </div>
        </q-item-section>
      </q-item>
    </template>
    <template #no-option>
      <q-item>
        <q-item-section class="text-grey">
          Type to search...
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>
