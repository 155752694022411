<script>
  import { CONFIG_SNIPPET_COLUMNS } from 'pages/ConfigSnippets/template'
  import { DEFAULT_PAGINATION, translateColumns } from 'src/utils'
  import { notifyError } from 'src/utils/notify'
  import ConfigSnippetFormDialog from 'pages/ConfigSnippets/ConfigSnippetFormDialog.vue'
  import ConfirmationDialog from 'components/Functional/ConfirmationDialog.vue'
  import ConfigSnippetPreviewDialog from 'pages/ConfigSnippets/ConfigSnippetPreviewDialog.vue'
  import {ref} from "vue";
  import ConfigSnippetContent from "pages/ConfigSnippets/ConfigSnippetContent.vue";

  export default {
    name: 'ConfigSnippetIndex',

    components: {
      ConfigSnippetContent,
      ConfigSnippetPreviewDialog,
      ConfirmationDialog,
      ConfigSnippetFormDialog
    },

    data() {
      return {
        DEFAULT_PAGINATION,

        loading: false,
        showSnippetFormDialog: false,
        showDeleteConfirmation: false,
        showPreviewDialog: false,
        configSnippets: [],
        selectedConfigSnippet: null,
        currentTab: ref('config')
      }
    },

    computed: {
      columns() {
        return translateColumns(this.$t, CONFIG_SNIPPET_COLUMNS);
      }
    },

    methods: {
      async getSnippets() {
        try {
          this.loading = true;
          const response = await this.$api.configSnippet.list()
          if (response.data) {
            this.configSnippets = response.data.list
            return
          }
          notifyError(this.$t('configSnippets.loadingFail'))
        } catch (error) {
          console.error(error)
          notifyError(this.$t('configSnippets.loadingFail'))
        } finally {
          this.loading = false;
        }
      }
    },

    created() {
      this.getSnippets()
    }
  }
</script>

<template>
  <q-page class="block q-pa-md relative-position">
    <h1>{{ $t('configSnippets.title') }}</h1>

    <q-tabs v-model="currentTab" align="left" dense active-color="primary" indicator-color="primary" narrow-indicator>
      <q-tab name="config" :label="$t('configSnippets.type.config')" :ripple="false" data-cy="configTab" />
      <q-tab name="template" :label="$t('configSnippets.type.template')" :ripple="false" data-cy="templateTab" />
    </q-tabs>
    <q-tab-panels v-model="currentTab">
      <q-tab-panel name="config" class="q-pa-md">
        <config-snippet-content :type="1" :content="configSnippets" />
      </q-tab-panel>
      <q-tab-panel name="template" class="q-pa-md">
        <config-snippet-content :type="2" :content="configSnippets" />
      </q-tab-panel>
    </q-tab-panels>

    <q-inner-loading
      :showing="loading"
      color="primary"
      :label="$t('configSnippets.processing')"
    />

  </q-page>
</template>
