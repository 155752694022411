<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'MenuLink',
  props: {
    title: {
      type: String,
      required: true
    },
    link: {
      type: String,
      default: '#',
      required: true
    },
    icon: {
      type: String,
      default: '',
      required: true
    },
    caption: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapState('preferences', ['isRefreshing']),

    menuLink() {
      return this.isRefreshing ? null : this.link
    },

    isOnSubpage() {
      return this.$route.path.startsWith(this.link + '/')
    }
  },

  methods: {
    toCamelCase(str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      }).replace(/\s+/g, '');
    }
  }
})
</script>

<template>
  <q-item
    class="app-menulink"
    :class="{'q-router-link--active': isOnSubpage}"
    clickable
    tag="a"
    :to="menuLink"
  >
    <q-item-section
      v-if="icon"
      avatar
    >
      <q-icon :name="icon" />
    </q-item-section>

    <q-item-section>
      <q-item-label>{{ $t('menu.routes.' + toCamelCase(title)) }}</q-item-label>
      <!--<q-item-label caption>{{ caption }}</q-item-label>-->
    </q-item-section>
  </q-item>
</template>

<style lang="scss">
  .app-menu a:hover {
    text-decoration: none;
  }
  .q-item__section--avatar {
    padding-right: 0;
    min-width: 2.5rem;
  }
  body.body--light {
    .q-item:not(.q-router-link--active) .q-item__section--side > .q-icon {
      filter: grayscale(1) brightness(0);
    }
  }
  body.body--dark {
    .q-item:not(.q-router-link--active) .q-item__section--side > .q-icon {
      filter: grayscale(1) brightness(5);
    }
  }
</style>
