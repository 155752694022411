<script>
import { defineComponent } from 'vue';
import { useQuasar } from 'quasar'
import { cacheUsageService } from 'src/services/cacheUsageService'
import { initialPageHelper, itemsPerPageHelper } from 'src/helpers/pagination-helper'
import LoadingIndicator from 'components/LoadingIndicator.vue'
import ItemsPerPage from 'components/ItemsPerPage.vue'
import Pagination from 'components/Pagination.vue'


export default defineComponent({
  name: 'CacheUsageOverview',
  components: {
    LoadingIndicator,
    Pagination,
    ItemsPerPage
  },
  setup() {
    const $q = useQuasar();

    return {
      qInstance: $q,
    }
  },
  data() {
    return {
      DEFAULT_TOTAL_MODULE_SIZE: '0 byte(s)',
      payload: {
        modules: [],
        cacheKeyData: {},
        totalModules: 0,
        totalModuleSize: 0,
        formattedTotalModuleSize: '',
      },
      requestedPage: this.$router.currentRoute._value.query.p ? this.$router.currentRoute._value.query.p : 1,
      requestedItemsPerPage: this.$router.currentRoute._value.query.i ? this.$router.currentRoute._value.query.i : 10,
      columns: [
        {
          name: 'flowExecutionId',
          required: true,
          label: this.$t('cacheUsage.overview.tableColumns.flowExecutionId'),
          align: 'left',
          field: 'flowExecutionId'
        },
        {
          name: 'jdmId',
          required: true,
          label: 'JDM ID',
          align: 'left',
          field: 'jdmId'
        },
        {
          name: 'flowName',
          required: true,
          label: this.$t('cacheUsage.overview.tableColumns.flowName'),
          align: 'left',
          field: 'flowName'
        },
        {
          name: 'formattedTotalBytes',
          required: true,
          label: this.$t('cacheUsage.overview.tableColumns.totalSize'),
          align: 'right',
          field: row => row.formattedTotalBytes
        },
        {
          name: 'details',
          required: true,
          label: null,
          align: 'left',
          field: null
        },
      ],
      isLoading: false,
      showKeys: false
    }
  },
  computed: {
    positionClass() { // TODO: Temp solution. Solve this with pagination component
      return {
        container: 'flex justify-end',
        pagination: 'q-mr-md',
        items: ''
      }
    },
    totalPages() {
      return Math.ceil(this.payload.totalModules / this.requestedItemsPerPage);
    }
  },
  methods: {
    goToDetail(event, row) {
      this.$router.push('/cache-usage/detail/' + row.flowExecutionId)
    },
    loadData() {
      this.$router.push({ query: { p: this.requestedPage, i: this.requestedItemsPerPage } })

      const cacheUsageList = cacheUsageService.getCacheUsageList(this.requestedPage, this.requestedItemsPerPage);

      this.isLoading = true;
      cacheUsageList.then(res => {

        this.payload.modules = res.data.modules ?? [];
        this.payload.cacheKeyData = res.data.keys ?? {};
        this.payload.totalModules = res.data.totalModules ?? 0;
        this.payload.totalModuleSize = res.data.totalModuleSize ?? 0;
        this.payload.formattedTotalModuleSize = res.data.formattedTotalModuleSize ?? this.DEFAULT_TOTAL_MODULE_SIZE;
        if(res.data.modules.length === 0 && this.requestedPage !== 1) {
          console.log("ERR")
          this.requestedPage = this.totalPages;
          this.loadData();
        }
      }).finally(() => this.isLoading = false);
    }
  },
  created() {
    this.loadData();

    this.requestedPage = initialPageHelper(this.$route.query);
    this.requestedItemsPerPage = itemsPerPageHelper(this.$route.query);
  }
})

</script>
<template>
  <q-page class="block">
    <div class="q-pa-md relative-position">
      <div class="row justify-between">
        <h1>{{ $t('cacheUsage.overview.headline') }}</h1>

        <h1 v-if="payload.formattedTotalModuleSize">{{ $t('cacheUsage.overview.overallTotalSize') }}: {{ payload.formattedTotalModuleSize }}</h1>
      </div>
      <p>{{ $t('cacheUsage.overview.desc') }}</p>


      <template v-if="!isLoading">
        <div class="cache-table-container row">

          <q-table class="app-cache-total-usage-table col-12 transparent q-mt-md" :rows="payload.modules"
                   @row-click="goToDetail" :no-data-label="$t('cacheUsage.noData')"
                   :rows-per-page-options="[requestedItemsPerPage]" row-key="flowExecutionId" :columns="columns"
                   color="primary" :hide-bottom="payload.modules.length > 0" :hide-pagination="true" flat>

            <template v-slot:body-cell-details="props">
              <q-td :props="props">
                <q-btn icon-right="img:assets/icons/link.svg" flat dense
                  :label="$q.platform.is.mobile ? $t('cacheUsage.overview.goToDetail') : null"
                  :title="$t('cacheUsage.overview.goToDetail')" size="xs" :disabled="isLoading"
                  @click.capture.stop="goToDetail(null, props.row)" />
              </q-td>
            </template>

            <template v-slot:body-cell-flowName="props">
              <q-td :props="props">
                #{{ props.row.flowId }} {{ props.row.flowName }}
              </q-td>
            </template>

            <template v-slot:no-data="{ icon, message, filter }">
              <div class="full-width row flex-center q-gutter-sm">
                <span>
                  {{ $t('cacheUsage.noData') }}
                </span>
              </div>
            </template>
          </q-table>
        </div>

        <template>
          <div :class="positionClass?.container" v-if="totalPages > 0"> <!-- 0 = loading state -->
            <pagination :pages="totalPages" v-model:model-value="requestedPage" @update:modelValue="loadData" />
            <items-per-page v-model:model-value="requestedItemsPerPage" @update:modelValue="loadData"
                            label="cacheUsage.overview.itemsPerPage" />
          </div>
        </template>




      </template>

      <loading-indicator v-if="isLoading" wrapper />

    </div>
  </q-page>
</template>

<style lang="scss" scoped>
.app-cache-total-usage-table {
  td:first-child {
    max-width: 1rem;
    background-color: $background2;
  }

  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  @media (max-width: $breakpoint-sm) {

    // Attention: max-width, since definition only required for small screen. Default on desktop is good.
    th,
    td {
      padding: 0 .3rem;
    }
  }
}

body.body--dark {
  .app-cache-total-usage-table {
    td:first-child {
      max-width: 1rem;
      background-color: $dark-page;
    }

    th:first-child,
    td:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
    }
  }
}
</style>
