<script>
  export default {
    name: 'PreferenceItem',

    props: {
      label: {
        type: String,
        required: false
      },

      loading: {
        type: Boolean,
        required: false,
        default: false
      },

      icon: {
        type: String,
        required: false,
        default: null
      },

      col: {
        type: String,
        required: false,
        default: 'col'
      }
    }
  }
</script>

<template>
  <div class="app-card-container flex justify-between q-pr-md q-pt-md" :class="this.$props.col">
    <q-card
      flat
      class="app-card full-width"
    >
      <q-icon
        v-if="icon"
        size="3rem"
        name="translate"
        class="app-card-bg-icon"
      />

      <q-item>
        <q-item-section>
          <q-item-label class="app-dashcard-label app-text-weight-semibold">
            <slot name="header">{{ label }}</slot>
          </q-item-label>
        </q-item-section>
      </q-item>

      <q-card-section
        data-cy="statisticCardValue"
        class="q-ml-sm"
        style="padding: unset;"
      >
        <slot></slot>
      </q-card-section>

      <q-inner-loading
        :showing="loading"
        color="primary"
        size="2em"
      />
    </q-card>
  </div>
</template>
