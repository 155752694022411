<script>
import {defineComponent} from 'vue'
import {debounce} from 'quasar'
import {VAceEditor} from "vue3-ace-editor";
import ace from 'ace-builds';
import "ace-builds/webpack-resolver";
import highlightMode from 'ace-builds/src-noconflict/mode-json?url';
import themeLight from 'ace-builds/src-noconflict/theme-iplastic?url';
import themeDark from 'ace-builds/src-noconflict/theme-pastel_on_dark?url';
import Ajv from "ajv";
import EditorToolbar from "components/JsonEditor/EditorToolbar.vue";
import { isJsonString, isJsonValid } from 'src/utils'

ace.config.setModuleUrl('ace/mode/json', highlightMode);
  ace.config.setModuleUrl('ace/theme/iplastic', themeLight);
  ace.config.setModuleUrl('ace/theme/pastel_on_dark', themeDark);

export default defineComponent({
    name: 'JsonEditor',

    components: {
      EditorToolbar,
      VAceEditor
    },

    props: {
      modelValue: {
        type: String,
        default: ""
      },

      lang: {
        type: String,
        default: "json"
      },

      wrapped: {
        type: Boolean,
        default: false
      },

      disabled: {
        type: Boolean,
        default: false
      },

      contrast: {
        type: Boolean,
        default: false
      },

      minHeight: {
        type: String,
        required: false,
        default: null
      },

      maxHeight: {
        type: String, // String, because this is passed with unit declaration
        required: false
      },

      maxWidth: {
        type: String, // Dito
        required: false
      },

      showToolbar: {
        type: Boolean,
        required: false,
        default: true
      },

      toolbarPosition: {
        type: String,
        required: false,
        default: 'right'
      },

      toolbarConfig: {
        type: Array,
        required: false
      },

      noValidation: {
        type: Boolean,
        default: false
      },

      allowEmpty: {
        type: Boolean,
        default: false
      },

      readonly: {
        type: Boolean,
        default: false
      },

      schema: {
        type: Object,
        default: {}
      },

      initTimeout: {
        default: 1,
      }
    },

    emits: [
      'is-valid-json',
      'is-valid-schema',
      'update:modelValue',
      'edited'
    ],

    data () {
      return {
        originCode: this.$props.modelValue, // Editor will be reset to this when cancel of any kind happens
        jsonCode: this.$props.modelValue,
        isValidJson: true,
        isValidSchema: true,
        originalContent: null,
        allFolded: false,
        initialized: false,
        isLoading: true,
        toolbarToken: 0, // Required for rerender and json code update in toolbar
        highlightLang: "json", // Extra variable required because directly referencing to lang prop throws mime type error
      }
    },

    computed: {
      hasCustomToolbar() {
        return this.$slots.hasOwnProperty('component-toolbar');
      },
      isPrettifiable() {
        return this.$props.lang === 'json';
      }
    },

    methods: {
      isInDialog() {
        let parent = this.$parent;
        while (parent) {
          if (parent.$options.name === 'QDialog') return true;
          parent = parent.$parent;
        }
        return false;
      },

      initialize() {
        // Set the origin code to the json code if it wasn't set for some reason
        if(!(typeof this.originCode !== 'undefined' && this.originCode !== null && this.originCode !== '')) this.originCode = this.jsonCode;

        this.setHighlightLang();
        this.validateInput();

        if (!this.isValidJson && !this.noValidation) {
          this.jsonCode = this.modelValue
        } else {
          if(!this.initialized && this.isPrettifiable && !(this.$props.disabled)) {
            setTimeout(() => { // Prettify needs a little timely offset on init, otherwise it won't work
              this.prettify();
            }, parseInt(this.isInDialog() === true ? 300 : this.$props.initTimeout));
          }
        }
        this.initialized = true;
      },

      async setHighlightLang() {
        // Dynamically import and set the required mode
        // Available languages can be found in node_modules/ace-builds/src-noconflict/mode-{...}
        let lang = this.$props.lang?.toLowerCase();
        if(lang !== '') {
          try {
            // Map any extension derivations here
            switch(lang){
              case 'txt': lang = 'text'; break;
              case 'js': lang = 'javascript'; break;
              case 'cs': lang = 'csharp'; break;
              case 'go': lang = 'golang'; break;
            }
            // Pass lang to editor
            await import(`ace-builds/src-noconflict/mode-${lang}?url`);
            ace.config.setModuleUrl(`ace/mode/${lang}`, highlightMode);
            this.highlightLang = lang;
          } catch (error) {
            console.info('Highlight mode for \'' + lang + '\' not found');
          }
        }
      },

      highlighter(code) {
        return highlight(code, languages.js); // languages.<insert language> to return html with markup
      },

      setupKeyboardShortcuts() {
        // Access the Ace Editor instance
        const aceEditor = this.$refs.editor._editor; // Get the Ace Editor instance

        // Define keyboard shortcut
        if(this.isPrettifiable) {
          const shortcut = {
            name: 'prettify',
            bindKey: {
              win: 'Ctrl-Alt-P',
              mac: 'Command-Option-P',
            },
            exec: () => {
              this.prettify();
            }
          };

          // Add the shortcut to the editor
          aceEditor.commands.addCommand(shortcut);
        }
      },

      clear() {
        let resetTo = '';
        if(this.lang === 'json') {
          // If this is a json, try to respect the original code type (object or array). If unknown, reset to object
          resetTo = this.originCode?.startsWith('[') ? '[]' : '{}';
        }
        this.jsonCode = resetTo;
        this.isValidJson = true;
        this.toolbarToken += 1; // Required for rerender and json code update in toolbar
        this.$emit('update:modelValue', this.jsonCode);
      },
      reset() {
        this.jsonCode = this.originCode;
      },
      onBlur() {
        this.$emit('edited', !(this.originalContent === this.modelValue));
        this.validateInput()
      },

      // Function to recursively parse and validate JSON properties
      parseAndValidateJson(json) {
        for (const key in json) {
          if (json.hasOwnProperty(key)) {
            const value = json[key];

            // Recursive call for nested objects
            if (typeof value === 'object') if(!this.parseAndValidateJson(value)) return false;

            // validate a likely JSON string. Check first if it is a JSON string then validate its structure
            if (typeof value === 'string' && isJsonString(value)) {
              if(value.trim().startsWith('{{') || value.trim().startsWith('{%') ) return true; // Skip if it is a template string
              return isJsonValid(value);

            }
          }
        }

        return true
      },

      validateInput() {
        if (!this.jsonCode) return

        try {
          const parsedJson = JSON.parse(this.jsonCode)
          this.isValidJson = this.parseAndValidateJson(parsedJson)
          this.validateSchema(parsedJson)
        } catch (error) {
          this.isValidJson = false
        }

        this.$emit('is-valid-json', this.isValidJson)
      },

      editorInit() {
        this.jsonCode = this.originCode;
        this.validateInput();
      },

      prettify() {
        if(this.isPrettifiable && this.jsonCode) {
          try {
            this.jsonCode = JSON.stringify(JSON.parse(this.jsonCode), null, 2);
          } catch (error) {
            console.error('Error prettifying JSON: ', error);
          }
        }
      },

      codeChange: debounce(function() {
        this.toolbarToken += 1; // force rerender
        if (!this.jsonCode) {
          if (!this.allowEmpty) {
            this.isValidJson = false;
            this.$emit('is-valid-json', this.isValidJson);
            return;
          }

          this.isValidJson = true
          this.$emit('is-valid-json', this.isValidJson);
          this.$emit('update:modelValue', '');
          return
        }

        this.validateInput()
        if (this.isValidJson || this.noValidation) {
          this.$emit('update:modelValue', this.jsonCode);
        }
      }, 300),

      validateSchema(parsedJsonCode) {
        const ajv = new Ajv({strict: false});
        const validate = ajv.compile(this.$props.schema); // Compile the schema
        this.isValidSchema = validate(parsedJsonCode);
        this.$emit('is-valid-schema', this.isValidSchema);
      },

      readonlyCheck(event) {
        if (event.key === 'Escape' || event.keyCode === 27) {
          return; // Do nothing if the ESC key was pressed
        }
        if (this.$props.disabled || this.$props.readonly) this.$store.dispatch('alert/error', 'general.readOnly', { root: true });
      }
    },

    mounted() {
      this.originalContent = this.modelValue;
      this.isLoading = false;
      this.setupKeyboardShortcuts();
    },

    watch: {
      modelValue: {
        handler() {
          this.jsonCode = this.modelValue ? this.modelValue : ""
          this.initialize()
        },
        immediate: true
      }
    }
  })
</script>

<template>
  <div
      class="app-editor fit flex relative-position"
      :class="{
          'custom-max-height': maxHeight,
          'invalid-code': !isValidJson && !noValidation
        }"
      :style="{ 'max-height': maxHeight, 'max-width': maxWidth }"
  >

    <div v-if="hasCustomToolbar" class="app-component-toolbar full-width">
      <slot name="component-toolbar"></slot>
    </div>

    <!-- Toolbar -->
    <editor-toolbar
        v-if="initialized && showToolbar && !isLoading"
        :code="jsonCode"
        :original-code="originCode"
        :lang="lang"
        :editor-ref="$refs.editor._editor"
        :config="toolbarConfig"
        :position="toolbarPosition"
        :prettifiable="isPrettifiable"
        :readonly="$props.readonly || $props.disabled"
        :is-valid-json="isValidJson"
        @clear="clear"
        @reset="reset"
        @prettify="prettify"
        :key="toolbarToken.toString()"
    />

    <!--  Editor  -->
    <div class="full-width" :class="{'sq-editor-offset': !isValidJson && !noValidation}">

      <v-ace-editor
          ref="editor"
          v-model:value="jsonCode"
          :lang="highlightLang"
          :theme="$q.dark.isActive ? 'pastel_on_dark' : 'iplastic'"
          :options="{ useWorker: false }"
          v-bind="$attrs"
          :highlight="highlighter"
          class="json-editor q-my-xs"
          :class="{
            'app-editor-light': !$q.dark.isActive,
            'app-editor-dark': $q.dark.isActive,
            'q-py-lg': !wrapped,
            'contrast': contrast,
            'custom-min-height': minHeight
          }"
          :style="{'min-height': minHeight}"
          data-cy="jsonEditor"
          :readonly="disabled || $props.readonly"
          @init="editorInit"
          @blur="onBlur"
          @change="codeChange"
          @keydown="readonlyCheck"
      />

      <div
        v-if="!isValidJson && !noValidation"
        class="text-red text-weight-bold q-ml-xs q-mt-xs sq-invalid-json-notif">
        <div class="q-mx-sm">{{ $t('general.invalidJson') }}</div>
      </div>
      <div
          v-if="!isValidSchema && !noValidation"
          class="text-red text-weight-bold q-ml-xs">
        {{ $t('general.invalidSchema') }}
      </div>
    </div>
    <!--  Editor End  -->
  </div>
</template>

<style lang="scss">
  .json-editor, .app-editor {
    z-index: 2998;
  }
  .json-editor {
    background: $background;
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
    min-width: 100%;
    resize: both; // If event handling is needed, add Quasar's resize observer
    &:not(.custom-min-height) {
      min-height: 12rem;
      .json-editor-wrapper {
        .json-editor__container {
          min-height: 80px;
        }
      }
    }
    &.contrast {
      background: $background2;
      color: $dark;
    }
    &:hover {
      border: $layout-border;
    }
    &:focus-within {
      box-shadow: 0 0 3px $secondary;
    }
  }
  .json-editor__textarea:focus {
    outline: none;
  }

  .json-editor-wrapper {
    .json-editor__container {
      min-height: 80px;
      height: 100%;
    }
  }

  body.body--light {
    .json-editor {
      background: $background;
      color: $dark;
      &.contrast, .contrast {
        background: $background2;
      }
    }
    textarea.json-editor__textarea::selection {
      background: #888;
    }
  }
  .q-editor-toolbar .q-btn.disabled {
    opacity: .3 !important;
  }

  .app-editor {
    &:not(.custom-max-height) {
      max-height: 50vh;
    }
  }

  .app-editor.invalid-code .json-editor {
    border: 1px solid $negative;
    &:focus-within {
      box-shadow: 0 0 3px $negative;
    }
  }


  .sq-editor-offset {
    margin-bottom: 2rem;
  }
  .sq-invalid-json-notif {
    position: sticky;
    display: flex;
    justify-content: flex-end;
  }

  .ace_editor {
    font-size: .75rem;

    .ace_variable {
      font-style: normal;
    }
    .ace_fold-widget {
      transform: scale(1.2);
    }
    .ace_marker-layer .ace_bracket {
      padding: 0.2rem;
      transform: translateX(1px);
      background-color: transparentize($secondary, .8);
    }
    .ace_print-margin {
      opacity: .2;
    }
  }
  .ace_editor.app-editor-light, .ace_marker-layer .ace_bracket {
    background-color: $background2;
    .ace_gutter, .ace_marker-layer .ace_active-line {
      background-color: $background;
    }
  }
  .ace_editor.app-editor-dark, .ace_gutter {
    background-color: $dark-page;
    .ace_marker-layer .ace_active-line, .ace_marker-layer .ace_bracket {
      background-color: $dark;
    }
  }

  body.body--dark {
    .json-editor {
      color: $light;
      caret-color: white;
      background: $dark-page;
      &.contrast, .contrast {
        background: #000;
      }
    }
    textarea.json-editor__textarea::selection {
      background: #444;
    }
    .ace-pastel-on-dark .ace_gutter {
      background-color: mix($dark-page, $primary-20, $mixVal);
      color: $light;
    }
  }
</style>
