<script>
  import { defineComponent } from 'vue'
  const DEFAULT_PAGINATION_OPTIONS = [5, 10, 15, 25, 50];

  export default defineComponent({
    name: 'ItemsPerPage',

    props: {
      paginationOptions: {
        type: Array,
        required: false,
        default: () => [...DEFAULT_PAGINATION_OPTIONS] // Copy the default array, otherwise a reference will be passed and the default array will be modified by the unshift function
      },

      modelValue: {
        type: Number,
        default: 10
      },

      label: {
        type: String,
        default: 'general.itemsPerPage'
      }
    },

    emits: ['update:modelValue'],

    data () {
      return {
        localValue: this.modelValue,
        isLoading: true
      }
    },

    computed: {
      requestedItemsPerPage: {
        get() {
          return this.modelValue;
        },

        set(val) {
          this.localValue = val;
          this.$emit('update:modelValue', val);
        }
      }
    },

    mounted() {
      const user = JSON.parse(localStorage.getItem('user'));
      const preferredItemsPerPage = parseInt(user.user_preference.itemsPerPage);
      if (preferredItemsPerPage && !isNaN(preferredItemsPerPage)) {
        // Check if the value already exists in paginationOptions
        const existsInOptions = this.$props.paginationOptions.includes(preferredItemsPerPage);
        if (!existsInOptions) {
          this.$props.paginationOptions.unshift(preferredItemsPerPage);
        }
      }
      this.isLoading = false;
    }
  })
</script>

<template>
  <q-select
    v-if="!isLoading"
    v-model.number="requestedItemsPerPage"
    :label="$t(this.label)"
    behavior="menu"
    dense borderless
    :options="paginationOptions"
    class="app-items-per-page-select"
  />
</template>

<style lang="scss">
.app-items-per-page-select {
  background-color: $background2;
  width: 10rem;
  border-radius: $br;
  padding-left: 12px;
}

body.body--dark {
  .app-items-per-page-select {
    background-color: $dark;
  }
}
</style>
