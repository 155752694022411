<script>
import { maxLen, required } from 'pages/JsonToForm/Helpers/validations'
import { notifyError, notifySuccess } from 'src/utils/notify'
import { SNIPPET_TYPES } from 'pages/ConfigSnippets/template'
import JsonEditor from 'components/JsonEditor.vue'
import SqDialog from 'components/Common/SqDialog.vue'

export default {
  name: 'ConfigSnippetFormDialog',

  components: {
    JsonEditor,
    SqDialog
  },

  props: {
    configSnippet: {
      type: Object,
      required: false
    },
    type: {
      type: Number,
      required: true
    }
  },

  emits: ['created', 'updated'],

  data() {
    return {
      SNIPPET_TYPES,

      loading: false,
      configSnippetForm: {
        snippetKey: null,
        snippetType: this.type,
        description: null,
        snippetValue: '{}'
      },
      errorBag: {}
    }
  },

  computed: {
    isUpdate() {
      return !!this.configSnippet?.id
    }
  },

  methods: {
    maxLen,
    required,

    initialize() {
      if (this.isUpdate) {
        this.configSnippetForm.snippetKey = this.configSnippet.key
        this.configSnippetForm.snippetType = this.configSnippet.type
        this.configSnippetForm.description = this.configSnippet.description
        this.configSnippetForm.snippetValue = this.configSnippet.value
      } else {
        this.configSnippetForm.snippetType = this.type
      }
    },

    handleHide() {
      this.configSnippetForm.snippetKey = null
      this.configSnippetForm.description = null
      this.configSnippetForm.snippetType = null
      this.configSnippetForm.snippetValue = '{}'
    },

    async handleSave() {
      const isValid = await this.$refs.configSnippetFormRef.validate()

      if (isValid) {
        // empty the error bag every time we attempt to hit the API
        this.errorBag = {}

        await(this.isUpdate ? this.updateSnippet() : this.createSnippet())
      }
    },

    async createSnippet() {
      try {
        this.loading = true;

        const payload = { ...this.configSnippetForm }
        payload.snippetValue = JSON.parse(payload.snippetValue);

        const response = await this.$api.configSnippet.create(payload)

        if (response.data) {
          notifySuccess(this.$t('configSnippets.creationSuccess'))
          this.$emit('created', response.data.data)

          return
        }

        notifyError(this.$t('configSnippets.creationFail'))
      } catch (error) {
        console.error(error)

        // fill the error bag if there are some validation errors
        if (error.response.data?.errors) {
          this.errorBag = error.response.data.errors;
        }

        notifyError(this.$t('configSnippets.creationFail'))
      } finally {
        this.loading = false;
      }
    },

    async updateSnippet() {
      try {
        this.loading = true

        const payload = { ...this.configSnippetForm }
        payload.snippetValue = JSON.parse(payload.snippetValue);

        const response = await this.$api.configSnippet.update(this.configSnippet.id, payload)

        if (response.data) {
          notifySuccess(this.$t('configSnippets.updateSuccess'))
          this.$emit('updated', response.data.data)

          return
        }

        notifyError(this.$t('configSnippets.updateFail'))
      } catch (error) {
        console.error(error)

        // fill the error bag if there are some validation errors
        if (error.response.data?.errors) {
          this.errorBag = error.response.data.errors;
        }

        const response = error.response
        if (response?.status === 404) {
          return notifyError(this.$t('configSnippets.notFound'))
        }

        notifyError(this.$t('configSnippets.updateFail'))
      } finally {
        this.loading = false
      }
    },

    validateSnippetKey(value) {
      // Set the compare pattern. Value should start and end with a double '%%'
      const regex = /^%%.*%%$/;

      // perform check
      if (!regex.test(value)) {
        return this.$t('configSnippets.regexFailed');
      }

      return null;
    },

    handleValueChange() {}
  }
}
</script>

<template>
  <sq-dialog
    type="create"
    size="md"
    :loading="loading"
    @save="handleSave"
    @show="initialize"
    @hide="handleHide"
  >
    <template #title>
      {{ isUpdate ? $t('configSnippets.dialog.title.update', {title: configSnippet.key}) : $t('configSnippets.dialog.title.create') }}
    </template>

    <template #content>
      <q-form
        ref="configSnippetFormRef"
        class="q-my-sm"
      >
        <q-input
          dense outlined
          v-model="configSnippetForm.snippetKey"
          :disable="isUpdate"
          clearable
          :rules="[
            required($t('configSnippets.required')),
            maxLen($t('configSnippets.maxLength', {size: 255}), 255),
            validateSnippetKey
          ]"
          :error="!!errorBag.snippetKey"
          :error-message="errorBag.snippetKey"
          :label="$t('configSnippets.snippetKey')"
          class="q-mb-md"
        />

        <q-select
          v-model="configSnippetForm.snippetType"
          clearable
          dense outlined
          options-dense
          emit-value
          map-options
          :rules="[
            required($t('configSnippets.required'))
          ]"
          :error="!!errorBag.snippetType"
          :error-message="errorBag.snippetType"
          :label="$t('configSnippets.snippetType')"
          :options="SNIPPET_TYPES"
          class="q-mb-md"
          readonly
        />

        <q-input
          v-model="configSnippetForm.description"
          clearable
          dense outlined
          autogrow
          :rules="[maxLen($t('configSnippets.maxLength', {size: 500}), 500)]"
          :error="!!errorBag.description"
          :error-message="errorBag.description"
          :label="$t('configSnippets.description')"
          class="q-snippet-description q-mb-md"
        />

        <json-editor
          v-model="configSnippetForm.snippetValue"
          @update:model-value="handleValueChange"
        />
      </q-form>
    </template>
  </sq-dialog>
</template>

<style lang="scss">
 .q-snippet-description textarea {
   min-height: 26px !important; // Remove scrollbar that gets set by wrong quasar style attribution
 }
</style>
