<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'StatusBadge',
    props: {
      running: {
        type: Boolean,
        required: true
      },
      status: {
        required: true
      },
      singleBadge: {
        type: Boolean,
        required: false,
        default: false
      },
      runningTooltip: {
        type: String,
        required: false,
        default: ''
      },
      positiveTooltip: {
        type: String,
        required: false,
        default: ''
      },
      negativeTooltip: {
        type: String,
        required: false,
        default: ''
      }
    }
  })
</script>

<template>
  <div v-if="running">
    <span>
      <q-spinner-dots color="primary" size="sm" />
      <q-tooltip v-if="runningTooltip" class="app-tooltip-mobile">{{ runningTooltip }}</q-tooltip>
    </span>
  </div>
  <template v-if="!running">
    <q-icon v-if="status" name="check_circle" color="positive" size="xs">
      <q-tooltip v-if="positiveTooltip" class="app-tooltip-mobile sq-tooltip-positive">{{ positiveTooltip }}</q-tooltip>
    </q-icon>
    <q-icon v-if="!status" name="cancel" color="negative" size="xs">
      <q-tooltip v-if="negativeTooltip" class="app-tooltip-mobile sq-tooltip-negative">{{ negativeTooltip }}</q-tooltip>
    </q-icon>
  </template>
</template>
