<script>
  import { required, number, min, max } from 'pages/JsonToForm/Helpers/validations'
  import { debounce } from 'lodash'
  import { notNullOrUndefined } from 'src/utils'
  import SqMarkdownDisplay from 'components/Common/SqMarkdownDisplay.vue'

  export default {
    name: 'SqInputNumberField',
    components: { SqMarkdownDisplay },

    props: {
      modelValue: {
        type: [Number, String],
        required: false
      },

      defaultValue: {
        required: false
      },

      validations: {
        type: Array,
        required: false,
        default: () => []
      }
    },

    emits: ['update:model-value'],

    data() {
      return {
        value: this.modelValue
      }
    },

    computed: {
      rules () {
        return this.validations.map(item => {
          if (item === 'required') return this[item](this.$t('common._validationMessages.required'))

          return this[item.name](item.message || this.$t('common._validationMessages.invalidData'), item.conditional)
        });
      }
    },

    methods: {
      notNullOrUndefined,
      required,
      number,
      min,
      max,

      updateModelValue: debounce(async function() {
        const isValid = await this.$refs.inputNumberRef.validate(this.value)

        if (isValid) this.$emit('update:model-value', this.value)
      }, 300),

      setDefaultValue() {
        this.value = this.defaultValue

        this.updateModelValue()
      },

    },

    watch: {
      modelValue(value) {
        if (value !== this.value) {
          this.value = value
        }
      }
    }
  }
</script>

<template>
  <div>
    <q-input
      ref="inputNumberRef"
      v-model.number="value"
      v-bind="$attrs"
      clearable
      dense
      outlined
      filled
      :rules="[number(), ...rules]"
      :label="$attrs.label || 'Key Name'"
      class="q-py-md"
      @update:model-value="updateModelValue"
    >
      <template #append>
        <q-btn
          v-if="notNullOrUndefined(defaultValue)"
          icon="refresh"
          dense
          flat
          @click="setDefaultValue"
        />
      </template>
    </q-input>

    <div v-if="$attrs.description" class="text-caption">
      <sq-markdown-display :markdown="$attrs.description" />
    </div>
  </div>
</template>
