<script>
  import SqDialog from 'components/Common/SqDialog.vue'
  import JsonEditor from 'components/JsonEditor.vue'
  export default {
    name: 'ConfigSnippetPreviewDialog',

    components: {
      JsonEditor,
      SqDialog
    },

    props: {
      configSnippet: {
        type: Object,
        required: false
      }
    },

    computed: {
      jsonConfig() {
        if (this.configSnippet?.id) {
          const value = this.configSnippet.value;

          return typeof value === 'string' ? value : JSON.stringify(value)
        }

        return '';
      },
      getTypeLabel() {
        if(this.configSnippet) {
          if(this.configSnippet.type === 1) return this.$t('configSnippets.type.config') + " ";
          if(this.configSnippet.type === 2) return this.$t('configSnippets.type.template') + " ";
        }
        return "";
      }
    }
  }
</script>

<template>
  <sq-dialog
    type="create"
    size="md"
    :cancel-button-label="$t('general.close')"
  >
    <template #title>
      {{ $t('configSnippets.previewTitle', {type: getTypeLabel }) }} - {{ configSnippet?.key }}
    </template>

    <template #content>
      <json-editor
        :model-value="jsonConfig"
        max-height="none"
        readonly
        :show-toolbar="false"
      />
    </template>

    <!--  This is a hack to replace the save button the SqDialog with an empty space. We don't want the Save button to be showing here  -->
    <template #saveButton><span class="hidden">&nbsp;</span></template>
  </sq-dialog>
</template>
