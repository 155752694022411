<script>
  import { FIELD_TYPE_OPTIONS } from 'pages/JsonToForm/utils'
  import useLocalize from 'src/composables/useLocalize'

  import { defineComponent } from 'vue'
  export default defineComponent({
    name: 'FieldTypeSelector',

    props: {
      modelValue: {
        required: true
      },

      removable: {
        type: Boolean,
        required: false,
        default: false
      },

      only: {
        type: Array,
        required: false
      }
    },

    emits: ['update:model-value', 'remove'],

    data() {
      return {
        fieldType: this.modelValue,
        selectableFieldTypes: this.only ? FIELD_TYPE_OPTIONS.filter(opt => this.only.includes(opt.value)) : FIELD_TYPE_OPTIONS
      }
    },

    computed: {
      baseSelectableOptions() {
        let fields = FIELD_TYPE_OPTIONS

        if (this.only) {
          fields = FIELD_TYPE_OPTIONS.filter(opt => this.only.includes(opt.value))
        }

        return useLocalize(this.$t, fields)
      }
    },

    methods: {
      handleFieldTypeFilter(val, update) {
        if (!val) {
          update(() => {
            this.selectableFieldTypes = this.baseSelectableOptions
          })

          return
        }

        update(() => {
          const query = val.toLowerCase()
          this.selectableFieldTypes = this.baseSelectableOptions.filter(type => type.label.toLowerCase().indexOf(query) > -1)
        })
      },

      handleFieldTypeChange() {
        this.$emit('update:model-value', this.fieldType)
      }
    },

    watch: {
      modelValue(value) {
        this.fieldType = value
      }
    }
  })
</script>

<template>
  <q-select
    v-model="fieldType"
    clearable
    dense outlined
    options-dense
    filled
    emit-value
    map-options
    use-input
    :label="`* ${$t('jsonToForm.formBuilder.form.fieldType') }`"
    :options="selectableFieldTypes"
    class="q-py-sm"
    @filter="handleFieldTypeFilter"
    @update:model-value="handleFieldTypeChange"
  >
    <template #after>
      <q-btn
        v-if="removable"
        dense
        flat
        color="negative"
        icon="delete"
        @click="$emit('remove')"
      />
    </template>
  </q-select>
</template>
