<script>
  import { defineComponent, defineAsyncComponent, ref, onBeforeUnmount } from 'vue'
  import { useQuasar } from 'quasar';
  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import DateDisplay from 'components/DateDisplay.vue'

  export default defineComponent({
    name: 'FlowExecutionActionBar',
    components: {
      LoadingIndicator,
      DateDisplay,
      Logs: defineAsyncComponent(() => import('components/Logs/Logs.vue'))
    },
    props: {
      treeLength: {
        type: Number,
        default: 0,
        required: false
      },
      calcHeaderProgressDifferently: {
        type: Boolean,
        required: true
      },
      finished: {
        type: Boolean,
        required: true
      }
    },
    emits: ["updateFilter", "expand", "updatePending", "updateCompleted", "refresh", "updateProgressDisplay", "autorefreshSeconds"],
    setup () {
      const filter = ref('');
      const filterRef = ref(null);
      const $q = useQuasar();
      return {
        qInstance: $q,
        filter,
        filterRef,
        tab: ref('list')
      }
    },
    data () {
      onBeforeUnmount(() => {
        this.killLoop();
      });
      return {
        expandedTreeNodes: ref([]), // init
        expanded: true,
        hideCompleted: ref(false),
        hidePending: ref(false),
        autoRefreshing: false,
        autoRefreshOptions: [2.5, 5, 10, 20, 30], // in seconds
        autoRefreshSeconds: 0, // used to display the correct active state in dropdown and info
        refreshModel: ref(false),
        autoRefreshLooping: null,
        isLoading: false,
        currentFlow: null,
        toggleNodeHeaderProgressDisplay: ref(this.$props.calcHeaderProgressDifferently)
      }
    },
    methods: {
      refreshTree() {
        this.$emit("refresh");
      },
      toggleAutoRefresh() {
        this.autoRefresh = !this.autoRefresh;
      },
      expandOrCollapseAll() {
        this.expanded = !this.expanded;
        this.$emit("expand");
      },
      updateFilter() {
        this.$emit("updateFilter", this.filter);
      },
      resetFilter () {
        this.filter = '';
        this.filterRef = ref(null);
        this.updateFilter();
      },
      setAutoRefresh(seconds) {
        clearTimeout(this.autoRefreshLooping);
        this.autoRefreshSeconds = seconds;
        this.$emit('autorefreshSeconds', this.autoRefreshSeconds);
        if(seconds > 0) {
          this.autoRefreshing = true;
          this.autoRefreshLoop(seconds);
        } else {
          this.autoRefreshing = false;
        }
      },
      autoRefreshLoop(seconds) {
        this.$emit("refresh");
        this.autoRefreshLooping = setTimeout(() => {
          if(this.autoRefreshing === true) {
            this.autoRefreshLoop(seconds);
          }
        }, 1000 * seconds);
      },
      hidePendingFctn() {
        this.hidePending = !this.hidePending;
        this.$emit("updatePending");
      },
      hideCompletedFctn() {
        this.hideCompleted = !this.hideCompleted;
        this.$emit("updateCompleted");
      },
      updateProgressDisplay() {
        this.$emit("updateProgressDisplay", this.toggleNodeHeaderProgressDisplay);
      },
      killLoop() {
        this.autoRefreshSeconds = 0;
        clearTimeout(this.autoRefreshLooping);
        this.autoRefreshLooping = null;
      }
    },
    mounted() {
      if(this.autoRefresh === true) this.autoRefreshLoop();
    },
    watch: {
      finished() {
        this.killLoop();
      }
    }
  })
</script>

<template>

  <div class="app-tree-action-bar flex justify-between q-ml-none q-mt-none">
    <div class="flex q-gutter-md full-width">

      <q-input dense outlined ref="filterRef"
               v-model="filter"
               :label="$t('flow.executions.detail.filter')"
               :disabled="isLoading"
               @update:model-value="updateFilter()"
      >
        <template v-slot:append>
          <q-icon v-if="filter !== ''" name="clear" class="cursor-pointer" @click="resetFilter" />
        </template>
      </q-input>

      <q-btn flat dense
             :icon-right="expanded === true ? 'unfold_less' : 'unfold_more'"
             :label="expanded === true ? $t('flow.executions.detail.collapseAll') : $t('flow.executions.detail.expandAll')"
             class="app-expand-btn q-pr-sm justify-end"
             :disabled="isLoading"
             @click.capture.stop="expandOrCollapseAll()"
      />

      <q-btn flat dense
             class="app-action-btn"
             :label="hidePending ? $t('flow.executions.detail.showPending') : $t('flow.executions.detail.hidePending')"
             :icon-right="hidePending ? 'visibility_on' : 'visibility_off'"
             :disable="isLoading"
             @click.capture.stop="hidePendingFctn()"
      />

      <q-btn flat dense
             class="app-action-btn"
             :label="hideCompleted ? $t('flow.executions.detail.showCompleted') : $t('flow.executions.detail.hideCompleted')"
             :icon-right="hideCompleted ? 'visibility_on' : 'visibility_off'"
             :disable="isLoading"
             @click.capture.stop="hideCompletedFctn()"
      />

      <span>
        <q-tooltip
            anchor="top middle" self="bottom middle"
            v-if='finished'
            class="app-tooltip-mobile"
        >
          {{ $t('flow.executions.detail.alreadyFinished') }}
        </q-tooltip>
        <q-btn-dropdown split flat dense icon-right="autorenew"
                        :label="$t('flow.executions.detail.reload')"
                        class="app-flow-execution-refresh-btn justify-end app-action-btn q-mr-sm fit"
                        :class="{ 'loading': isLoading }"
                        v-model="refreshModel"
                        @click='!isLoading ? refreshTree() : null'
                        :disable-main-btn="isLoading"
                        :disable-dropdown="isLoading || finished"
        >
          <q-list>
            <q-item :disable="isLoading" :active="autoRefreshSeconds <= 0" clickable v-close-popup @click="setAutoRefresh(0)">
              <q-item-section>
                <q-item-label>{{ $t('flow.executions.detail.autoRefresh.disable') }}</q-item-label>
              </q-item-section>
            </q-item>

            <template v-for="seconds in autoRefreshOptions">
              <q-item :disable="isLoading" :active="autoRefreshSeconds === seconds" clickable v-close-popup @click="setAutoRefresh(seconds)">
                <q-item-section>
                  <q-item-label>{{ $t('flow.executions.detail.autoRefresh.enable', { seconds: seconds }) }}</q-item-label>
                </q-item-section>
              </q-item>
            </template>

          </q-list>
        </q-btn-dropdown>
      </span>

      <q-checkbox v-model="toggleNodeHeaderProgressDisplay"
                  @update:model-value="updateProgressDisplay"
                  :label="$t('flow.executions.detail.toggleNodeHeaderProgressDisplay')"
      />

    </div>
  </div>

  <div v-if="treeLength > 0 && autoRefreshing && autoRefreshSeconds > 0" class="app-flow-auto-refresh-info flex col-12 items-end justify-start q-my-sm q-mx-md">
    <q-icon name="warning_amber" class="q-mb-xs q-mr-xs" />
    {{ $t('flow.executions.detail.autoRefresh.autoRefreshActivatedInfo', { seconds: autoRefreshSeconds }) }}
  </div>
  <!--<div class="app-flow-auto-refresh-info q-ma-none" :class="{ 'visible': autoRefresh, 'hidden': !autoRefresh }">
    {{ $t('flow.executions.detail.autoRefreshOn') }}
  </div>-->


</template>

<style lang="scss">
  .app-tree-action-bar {
    @media (min-width: $breakpoint-xs) {
      position: sticky;
      top: 3.5rem;
    }
    background-color: $background2;
    z-index: 1;
    padding-bottom: .4rem;
    padding-top: .5rem;
  }
  .app-flow-execution-refresh-btn {
    button:first-of-type {
      padding-right: .5rem;
    }
    .q-btn__content {
      font-weight: 600;
    }
    &.loading button:first-of-type i {
      animation: running-animation 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      @keyframes running-animation {
        0% {
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(90deg);
        }
        50% {
          transform: rotate(180deg);
        }
        75% {
          transform: rotate(270deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  .app-all-filtered-info {
    color: $gray;
  }

  .app-tree-action-bar {
    .q-icon {
      display: inline-block;
    }
    .q-btn, .app-flow-execution-refresh-btn .q-btn__content {
      font-weight: 600;
      .q-icon:not(.q-btn-dropdown__arrow) {
        font-size: 1rem;
      }
    }
    .flex  {
      > *, > * .q-field__control {
        height: 2rem;
        .q-field__label {
          padding-top: unset;
        }
      }
    }
    .q-field:not(.q-field--focused.q-field--highlighted) {
      &.q-field--dense .q-field__label {
        transform: translateY(-3px);
      }
    }
  }

  body.body--dark {
    .app-tree-action-bar {
      background-color: $dark;
    }
  }
</style>
