export default {
  jsonToForm: {
    jsonToForm: 'Json To Form',
    jdmScreen: {
      flowConfig: 'Flow-Konfiguration',
      jdmForm: 'JDM-Formular',
      dialog: {
        confirmSwitch: 'Bestätigen Sie den Wechsel',
        confirmSwitchMessage: 'Ungültige Konfiguration. Möchten Sie wirklich in den Formularmodus wechseln? Dadurch gehen einige Informationen verloren.',
        confirmLeave: 'Bestätigen Sie das Verlassen',
        confirmLeaveMessage: 'Warnung: Nicht gespeicherte Änderungen. Seite verlassen, ohne zu speichern? Alle nicht gespeicherten Änderungen gehen verloren!'
      },
      viewMode: {
        fullView: 'Vollansicht',
        splitView: 'Geteilte Ansicht',
        expertMode: 'Expertenmodus'
      },
      notification: {
        failedToLoadFlow: 'Flow-Informationen konnten nicht geladen werden. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.',
        failedToLoadSchema: 'Formularschema konnte nicht geladen werden.',
        invalidForm: 'Ungültiges Formular. Bitte überprüfen Sie, ob Validierungsfehler vorliegen, beheben Sie diese und versuchen Sie erneut, den Vorgang zu speichern.',
        jdmSaved: 'JDM-Konfiguration gespeichert',
        jdmSaveFailed: 'JDM konnte nicht gespeichert werden. Bitte melden Sie dies Ihrem Administrator.',
        invalidConfigurationCheckConfig: 'Ungültige Konfiguration. Bitte überprüfen Sie Ihre Konfiguration und stellen Sie sicher, dass sie gültig ist.',
        invalidConfigurationCheckConfigCont1: 'Wenn Sie der Meinung sind, dass dies ein Problem darstellt, erstellen Sie bitte einen Bericht.',
        invalidConfigurationCheckConfigCont2: 'Sie können den Formularmodus deaktivieren, indem Sie den Modusschalter oben umschalten.'
      }
    },
    formBuilder: {
      formBuilder: 'Formularersteller',
      formConfigList: 'Formularkonfigurationsliste',
      createNewConfig: 'Neu',
      loadSchema: 'Schema laden',
      loadedSchema: 'Geladenes Schema',
      formBuilderListNote: 'Die hier erstellten Formulare werden nicht in der Cloud gespeichert. Alle werden lokal gespeichert. Wenn der Browser-Cache geleert wird, finden Sie die Liste hier.',
      formBuilderEmptyList: 'Es wurde noch keine Konfiguration angelegt.',
      closeBuilder: 'Ersteller schließen',
      form: {
        wrapperConfig: 'Wrapper-Konfiguration',
        fieldConfig: 'Feldkonfig',
        objectConfig: 'Objektkonfiguration',
        copySingleNode: 'Einzelnen Knoten kopieren',
        copyNodeWithChildren: 'Knoten mit untergeordneten Elementen kopieren',
        keyName: 'Eigenschaftsname',
        fieldLabel: 'Feldbeschriftung',
        valueType: 'Werttyp',
        fieldType: 'Feldtyp',
        dataType: 'Datentyp',
        tabLabel: 'Tab-Beschriftung | Tab-Beschriftungen',
        wrapperType: 'Wrapper-Typ | Wrapper-Typen',
        defaultCollapsed: 'Standardmäßig reduziert',
        searchKey: 'Suchbegriff | Suchbegriffe',
        addLocalization: 'Lokalisierung hinzufügen',
        input: 'Eingabefeld',
        inputNumber: 'Eingabefeld-Nummer',
        textArea: 'Textbereich',
        checkbox: 'Kontrollkästchen',
        switch: 'Schalter',
        select: 'Select-Box',
        datePicker: 'Datumsauswahl',
        dynamicInput: 'Dynamische Eingabe',
        stringToArrayMap: 'String-zu-Array-Wertzuordnung',
        propertyMapping: 'Eigenschaftszuordnung',
        dynamicObjectMapping: 'Dynamische Objektzuordnung',
        objectArrayMap: 'Objekt-Array-Zuordnung'
      },
      table: {
        configName: 'Konfigurationsname',
        editForm: 'Formular bearbeiten',
        previewForm: 'Formularvorschau',
        exportSchema: 'Schema exportieren',
        deleteForm: 'Formular löschen'
      },
      dialog: {
        deleteSchemaConfirmation: 'Sind Sie sicher, dass Sie diese Konfiguration entfernen möchten?',
        deleteConfig: 'Konfiguration löschen',
        deleteConfigMessage: 'Sind Sie sicher, dass Sie diese Objektkonfiguration entfernen möchten? Außerdem werden alle untergeordneten Konfigurationen gelöscht, und sobald Sie sie gespeichert haben, können Sie sie nie mehr wiederherstellen.',
        removeLocalizationMessage: 'Sind Sie sicher, dass Sie diese Konfiguration entfernen möchten? Nach dem Speichern können Sie die Änderungen nie mehr rückgängig machen.',
      },
      notification: {
        schemaDeleted: '"{name}" wurde gelöscht',
        formUpdated: 'Formular aktualisiert',
        formCreated: 'Formular erstellt',
        saveFormError: 'Formularkonfigurationsfehler. Bitte überprüfen Sie das Formular und versuchen Sie es erneut.'
      },
      validation: {
        isRequired: '{field} ist erforderlich | {field} sind erforderlich',
        invalidObjectConfig: 'Die Objektkonfiguration ist ungültig. Felder mit * sind Pflichtfelder.',
        invalidFieldConfig: 'Die Feldkonfiguration ist ungültig. Felder mit * sind Pflichtfelder.',
      },
      fieldConfigGenerator: {
        shouldMask: 'Soll Maskieren',
        isMultiple: 'Mehrzahl',
        isMultipleNotApplicable: 'Nicht anwendbar für Einzelwertauswahl',
        canAddNewValue: 'Kann neuen Wert hinzufügen',
        canAddNewValueNotApplicable: 'Nicht anwendbar für Einzelwertauswahl',
        searchKeys: 'Schlüssel suchen',
        description: 'Beschreibung',
        options: 'Optionen',
        selectAdditionalOption: 'Zusätzliche Option auswählen',
        confirmRemove: 'Entfernen bestätigen',
        confirmRemoveWarning: 'Sind Sie sicher, dass Sie diese Feldkonfiguration entfernen möchten? Einmal bestätigt, können diese Änderungen nicht mehr rückgängig gemacht werden.'
      }
    }
  }
}
