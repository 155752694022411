<script>
  import { defineComponent, defineAsyncComponent, ref } from 'vue'
  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import EndFlowModal from '../../Flow/Overview/components/FlowModal/EndFlowModal'
  import RestartFlowModal from "pages/Flow/Overview/components/FlowModal/RestartFlowModal";
  import FlowMeta from './components/FlowMeta.vue'
  import FlowExecutionTree from "./components/FlowExecutionTree";
  import {flowExecutionService} from "src/services";
  import { checkRunningFlowLoop, clearLooping } from 'src/helpers/auto-refresh'

  export default defineComponent({
    name: 'FlowExecutionDetail',

    components: {
      RestartFlowModal,
      EndFlowModal,
      FlowExecutionTree,
      LoadingIndicator,
      FlowMeta,
      Logs: defineAsyncComponent(() => import('components/Logs/Logs.vue'))
    },

    setup () {
      return {
        tab: ref('list')
      }
    },
    data () {
      return {
        showEndFlowModal: false,
        showRestartFlowModal: false,
        isLoading: true,
        currentFlow: null,
        animationFixVar: false,
        ranOneLastTime: null,
        autoRefreshLooping: null,
        flowId: null
      }
    },
    methods: {
      finished() {
        this.killLoop();
      },
      updateFlowStatus(flowDetails) {
        this.currentFlow = flowDetails;
      },

      animationFix() {
        this.animationFixVar = true;
        setTimeout(() => {
          this.animationFixVar = false;
        }, 500);
      },

      getFlowExecutionDetails() {
        flowExecutionService.getFlowExecutionDetails(this.flowId, (data) => {
          if(typeof data !== 'undefined') {
            if(!(!data || data?.data?.['@type'] === 'hydra:Error')) {
              let foundRunningFlow = false;
              // Update page
              if (data.active === true) foundRunningFlow = true;
              else this.killLoop();

              this.currentFlow = data;
              this.isLoading = false;

              this.runningFlow = foundRunningFlow;
              if(this.runningFlow) this.autoRefreshLooping = checkRunningFlowLoop(this.runningFlow, () => this.getFlowExecutionDetails());
            } else {
              this.$router.push('/flowexecutions');
              this.$store.dispatch('alert/error', 'flow.executions.detail.notFound', { root: true });
            }
          }
        })
      },
      handleFlowEnded() {
        this.showEndFlowModal = false;
        this.getFlowExecutionDetails();
      },

      handleFlowRestart(flowData) {
        this.showRestartFlowModal = false;
        flowExecutionService.getFlowExecutions(1, 10, this.flowId, null, (data) => {
          if(data['hydra:totalItems'] > 0) {
            const id = data['hydra:member'][0].id;
            this.$router.push('/flowexecutions/detail/' + id);
          }
        });
      },

      killLoop() {
        if(this.ranOneLastTime) {
          clearLooping(this.autoRefreshLooping);
          this.autoRefreshLooping = null;
        } else { // Edge case: process time overlapping. Make sure that we truly got the final state.
          setTimeout(() => {
            this.getFlowExecutionDetails();
            this.ranOneLastTime = 2; // Final refresh loop done, final refresh triggered
          }, 2500);
          this.ranOneLastTime = 1; // Final refresh loop triggered
        }

      }
    },
    created() {
      this.flowId = this.$route.params.flowid;
      this.getFlowExecutionDetails();
    },
    beforeUnmount() {
      this.ranOneLastTime = true;
      this.killLoop();
    }
  })
</script>

<template>
  <q-page class="block app-flow-execution-detail-page">
    <div>
      <div class="app-container-wrapper">
        <div class="app-headline-container q-pa-md flex">
          <loading-indicator v-if="isLoading" wrapper />
          <template v-if="!isLoading">
            <div class="flex row full-width">
              <h1>{{ currentFlow.flowName }} #{{ currentFlow.id }}</h1>
            </div>
            <div class="flex align-center items-baseline">
              <flow-meta :current-flow="currentFlow" :is-loading="isLoading" />

              <div class="row items-center q-mt-md">
                <q-btn v-if="this.currentFlow.active"
                       flat dense
                       class="app-action-btn q-px-sm q-mr-sm"
                       :label="$t('flow.overview.detail.end')"
                       :disable="isLoading"
                       @click="showEndFlowModal = true"
                       v-bind:data-cy="'buttonQuickEndFlow' + this.currentFlow.id"
                >
                  <q-icon name="do_disturb" class="q-ml-sm" />
                </q-btn>

                <q-btn v-if="!this.currentFlow.active"
                       flat dense
                       class="app-action-btn q-px-sm q-mr-sm"
                       :label="$t('flow.executions.detail.startNew')"
                       :disable="isLoading"
                       @click="showRestartFlowModal = true"
                       v-bind:data-cy="'buttonQuickEndFlow' + this.currentFlow.id"
                >
                  <q-icon name="restart_alt" class="q-ml-sm" />
                </q-btn>

                <q-btn
                  flat dense
                  :to="'/flow/' + currentFlow.flowId"
                  class="app-jump-to-execution app-action-btn q-px-sm"
                  :label="$t('flow.openFlow')"><q-icon name="img:assets/icons/link.svg" class="q-ml-sm" />
                </q-btn>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="q-pa-md">

        <div class="q-gutter-y-md">
          <q-tabs
            v-model="tab"
            dense
            inline-label
            align="left"
            class="app-flex-execution-detail-tabs app-wrap-overwrite q-mr-sm-lg"
            active-color="primary"
            indicator-color="primary"
            narrow-indicator
          >
            <q-tab name="list" icon="img:assets/icons/list.svg" :label="$t('flow.executions.detail.tabs.list')" @click="animationFix" class="q-pl-none q-pr-xs q-mr-md" />
            <q-tab name="logs" icon="img:assets/icons/logs.svg" :label="$t('flow.executions.detail.tabs.logs')" @click="animationFix" class="q-pl-none q-pr-xs" />
          </q-tabs>

          <q-tab-panels class="app-flow-execution-detail-panel"
                        :class="{'app-tab-transitioning': animationFixVar}"
                        v-model="tab">

            <q-tab-panel name="list">
              <loading-indicator v-if="isLoading" wrapper />
              <div class="q-py-md q-gutter-sm q-pt-none">
                <flow-execution-tree
                  v-if="currentFlow"
                  :current-flow="currentFlow"
                  :auto-refresh="autoRefreshLooping"
                  @update-flow-details="updateFlowStatus"
                  @finished="finished"
                />
              </div>
            </q-tab-panel>

            <q-tab-panel name="logs">
              <logs :flow-execution-filter="this?.currentFlow?.id.toString()" />
            </q-tab-panel>

          </q-tab-panels>

        </div>
      </div>

      <end-flow-modal
        v-model="showEndFlowModal"
        :flow-id="currentFlow?.id"
        :flow-name="currentFlow?.flowName"
        @success="handleFlowEnded"
      />

      <restart-flow-modal
        v-model="showRestartFlowModal"
        :flow-id="currentFlow?.flowId"
        :flow-name="currentFlow?.flowName"
        @success="handleFlowRestart"
      />
    </div>
  </q-page>
</template>

<style lang="scss">
.app-flow-execution-detail-panel, .app-flow-execution-detail-panel .q-panel.scroll {
  overflow: unset; // required for sticky action bar to work
}
.app-flow-execution-detail-panel.app-tab-transitioning.q-panel-parent {
  overflow: hidden; // to prevent elements flying visually out of the box
}
.app-flow-execution-detail-page .app-jump-to-execution {
  font-weight: 600;
}

.q-layout > div .app-jump-to-execution.app-action-btn .q-icon img {
  filter: invert(1);
}

body.body--dark {
  .app-flex-execution-detail-tabs {
    .q-tab:not(.q-tab--active) {
    .q-icon {
      filter: $darkmodize;
    }
  }
  }
}

.app-flex-execution-detail-tabs {
  .q-tab--active {
    .q-icon {
      filter: $primarize;
    }
  }
  .q-icon {
    width: 1rem;
    height: 1rem;
  }
}
</style>
