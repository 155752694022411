import { fileSystemService } from 'src/services'

export function fileSystemLoad({ dispatch, commit, state }) {
  commit('blockFileSystem');
  fileSystemService.fileSystemLoad(function(data) {
    if(typeof data !== "undefined") {
      commit('fileSystemLoad', data)
    }
  })
}

export function deleteFilesystem({ dispatch, commit, state }, filesystem) {
  commit('blockFileSystem');
  fileSystemService.deleteFilesystem(filesystem.replace("/api", ""), function (data) {
    if(typeof data !== "undefined" && typeof data.data.detail !== "undefined") {
      dispatch('alert/error', data.data.detail, {root: true});
    } else {
      dispatch('alert/success', 'browser.modal.deleteFilesystemSuccess', {root: true});
      dispatch('fileSystemLoad');
    }
  });
}
