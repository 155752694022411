<script>
import { defineComponent, ref } from 'vue'
import { mapActions, mapState } from 'vuex'
import usePaginator from 'src/composables/usePaginator'

import EntitySelection from 'components/EntitySelection.vue'
import EntityListing from './components/EntityListing.vue'
import JsonEditor from 'components/JsonEditor.vue'
import FilterPreset from 'src/components/FilterPreset.vue'
import EntityStorage from './components/EntityStorage.vue'
import EntityRefreshModal from './components/modal/EntityRefreshModal.vue'
import EntityClearModal from './components/modal/EntityClearModal.vue'
import EndFlowModal from "pages/Flow/Overview/components/FlowModal/EndFlowModal.vue";

export default defineComponent({
  name: 'Entity',

  components: {
    EndFlowModal,
    JsonEditor,
    FilterPreset,
    EntityListing,
    EntitySelection,
    EntityStorage,
    EntityRefreshModal,
    EntityClearModal
  },

  provide() {
    return {
      rowDeleted: this.rowDeleted
    }
  },

  setup () {
    const { routeQueryObserver } = usePaginator()
    // Workaround for json editor line number wrap issue. See UI-233.
    /* With q-resize-observer, we (re)calculate the size based on the app-entity-query-editor-wrapper, and take this
     value to update the json editor max width of listing rows accordingly */
    const parentContainer = ref(null)
    const showEntityRefreshModal = ref(false)
    const showEntityClearModal = ref(false)
    return {
      parentContainer,
      showEntityRefreshModal,
      showEntityClearModal,
      onResize (size) {
        parentContainer.value = size
      },
      routeQueryObserver
    }
  },

  data () {
    return {
      hasQuery: false,
      entityData: null,
      loading: false,
      hasFilter: null,
      edited: false,
      filters: {
        page: 1,
        query: "",
        queryModel: "{}"
      },
      refreshKeyToken: 0,
      requestSource: null
    }
  },

  computed: {
    ...mapState('entities', ['currentEntityName', 'entityList']),
    hasAnyFilter() {
      const q = this.filters.query;
      const qM = this.filters.queryModel;
      return (
          q && q !== '' && q !== '{}' && q !== '[]' || ( // Always display if there's an active filter set by an Getter
              qM && qM !== '' && qM !== '{}' && qM !== '[]' // Always display if there's a meaningful queryModel
          )
      )
    },

    entityTotalItems() {
      return this.entityData?.['hydra:totalItems'] || 0
    },

    entityTotalItemsLabel() {
      const entities = this.entityTotalItems;
      return entities.toLocaleString(this.$q.lang.isoName);
    },

    entityName() {
      return this.$route.params.entitylist
    },

    hasEntityItems() {
      return this.entityData && this.entityData['hydra:member']
    }
  },

  methods: {
    ...mapActions('entities', ['updateCurrentEntityName']),

    initialize() {
      this.updateCurrentEntityName(this.entityName)

      // get the possible page coming from the query param
      this.filters.page = Number(this.$route.query['page'] || this.filters.page);
      this.filters.query = this.$route.query['filter'] || "";
      if (this.filters.query !== '') {
        this.filters.queryModel = this.filters.query;
        this.hasQuery = true;
      }

      if (this.currentEntityName) {
        this.getEntityData()
      }
    },

    entityChanged() {
      this.filters.page = 1
      this.filters.queryModel = '';
      this.filters.query = '';
      this.getEntityData()
    },

    entityRefresh() {
      this.edited ? this.showEntityRefreshModal = true : this.handleRefresh()
    },
    handleRefresh() {
      this.showEntityRefreshModal = false;
      this.filters.queryModel = this.filters.query;
      this.refreshKeyToken += 1; // Forces rerender of entity list
      this.edited = false;
      this.getEntityData();
    },

    entityClear() {
      this.showEntityClearModal = true;
    },
    async handleClear() {
      this.loading = true;
      this.showEntityClearModal = false;
      const { data } = await this.$api.entityAPI.clearEntity(this.$route.params.entitylist)
      if(data.success === true) {
        this.hasFilter = false;
        this.getEntityData();
        this.$store.dispatch('alert/success', this.$t('entities.modal.deleteEntitySuccess', { entityList: this.$route.params.entitylist }), {root: true});
      } else {
        this.loading = false;
        this.$store.dispatch('alert/error', this.$t('entities.modal.deleteEntityError', { entityList: this.$route.params.entitylist }), {root: true});
      };
    },

    async getEntityData() {
      this.loading = true
      if (this.requestSource) {
        this.requestSource.cancel({message: 'Entity request change!'})
      }

      const cancelToken = this.$axios.CancelToken
      this.requestSource = cancelToken.source()

      const query = {
        page: this.filters.page,
        ...this.filters.query && { filter: this.filters.query }
      }

      try {
        this.hasFilter = !!this.filters.query

        const { data } = await this.$api.entityAPI.getEntityData(this.$route.params.entitylist, query, this.requestSource.token)


        if (data['@id']) {
          this.entityData = data
        }
      } catch (error) {
        if (!error?.cancelled) this.loading = false
      }
      this.loading = false;
    },

    setQuery(query) {
      try {
        this.filters.queryModel = query;
        this.refreshKeyToken += 1; // See UI-233: Rerender needed to prevent vue native prod build crash
        this.hasQuery = true;
      } catch(e) {
        console.error(e)
      }
    },

    rowDeleted(row) {
      if (this.hasEntityItems) {
        const index = this.entityData['hydra:member'].findIndex(item => item.uuid === row.uuid)

        if (index > -1) {
          this.entityData['hydra:member'].splice(index, 1)
        }
      }
    },

    clearQuery(doSearch = false) {
      try {
        this.filters.query = "";
        this.filters.queryModel = "";
        this.refreshKeyToken += 1; // See UI-233: Rerender needed to prevent vue native prod build crash
        this.$refs.filter.clear();
        if (doSearch && this.hasQuery) this.doSearch();
        this.hasQuery = false;
      } catch(e) {
        console.error(e);
      }
    },

    doSearch() {
      this.filters.query = this.filters.queryModel;
      if (this.filters.query !== '' && this.filters.query !== '{}') {
        this.$router.push({query: {filter: this.filters.query } });
      } else {
        this.$router.push(this.$route.path);
      }
      this.filters.page = 1;
      this.getEntityData()
    },

    pageChange(requestedPage) {
      const query = { page: requestedPage };
      if (this.filters.query) query.filter = this.filters.query;
      this.$router.push({ query });
      this.filters.page = requestedPage
      this.getEntityData()
    },

    triggerQueryByHotkey(event) {
      if ((event.key === 's' || event.key === 'Enter') && (event.ctrlKey || event.metaKey)) {
        event.stopPropagation();
        event.preventDefault();
        this.doSearch()
      }
    },

    checkForPendingEdits(data) {
      this.edited = data;
    },

    updateEntity() {
      this.hasFilter = !!this.filters.query;
    }
  },

  watch: {
    entityList: {
      handler() {
        if (!this.currentEntityName && !this.entityData) {
          const entityId = this.entityList[0]?.uuid;
          if(entityId) {
            this.$router.push('/documents/' + entityId);
            this.updateCurrentEntityName(entityId)
            this.filters.page = 1
            this.getEntityData();
          }

        }
      },
      deep: true
    }
  },

  created() {
    this.initialize()

    try {
      this.$eventBus?.listen('ENTITY_CHANGE', (entity) => {
        this.entityChanged(entity)
      })
    } catch(e) {
      console.warn(e)
    }
  },

  mounted() {
    this.updateEntity();

    this.routeQueryObserver(query => {
      const page = Number(query.page)
      const filter = query.filter

      if (page !== this.filters.page || filter !== this.filters.query) {
        this.filters.page = page > 0 ? page : this.filters.page
        this.filters.query = filter;

        this.getEntityData();
      }
    })
  },

  beforeUnmount() {
    this.$eventBus?.off('ENTITY_CHANGE')
  }
})
</script>

<template>
  <q-page class="block" style="padding-bottom: 30px">
    <div class="row">
      <div
        class="app-entity-page-container col-12 relative-position"
        :class="{'col-sm-8 col-md-9 col-lg-10': !($q.screen.xs || $q.screen.sm)}"
      >
        <div v-if="entityName">
          <div class="full-width flex inline justify-between items-center q-mb-sm q-px-sm q-px-sm-none">
            <h1>{{ $t('entities.title', {entity: currentEntityName}) }}</h1>
            <div class="relative-position">
              <span class="q-mr-sm">{{ $t('entities.totalItems', {totalItems: entityTotalItemsLabel}) }}</span>
              <q-btn
                flat
                dense
                icon-right="refresh"
                :label="$t('entities.refresh')"
                :disabled="loading"
                :loading="loading"
                class="app-action-btn q-pr-sm justify-end q-ma-xs q-ma-none-lg"
                @click.capture.stop="entityRefresh"
              />
              <q-btn
                  flat
                  dense
                  icon-right="delete"
                  :label="$t('entities.delete', {entity: currentEntityName})"
                  :disabled="loading || !hasEntityItems?.length"
                  :loading="loading"
                  class="app-action-btn q-pr-sm justify-end q-ma-xs q-ma-none-lg"
                  @click.capture.stop="entityClear"
              />
            </div>
          </div>

          <div class="app-entity-query-editor-wrapper row q-pa-sm" ref="wrapper">
            <q-resize-observer @resize="onResize" />
            <json-editor
              v-model:model-value="filters.queryModel"
              preset-set
              @update-preset="updateEntity"
              ref="filter"
              style="margin: 0"
              wrapped
              contrast
              min-height="6rem"
              @keydown.capture="triggerQueryByHotkey($event)"
              :key="'filter' + refreshKeyToken"
              allow-empty
            >
              <template #component-toolbar>
                <div class="app-entity-query-controls row justify-between full-width">
                  <div class="app-entity-query-control-wrapper q-mr-auto q-ml-sm">

                    <entity-storage :filters="filters" :set-query="setQuery" :do-search="doSearch" />

                    <!-- Note: clear button must always be available, otherwise an insertBefore error is thrown internally on prod builds - see UI-369 -->
                    <!-- So as a workaround we can use disable states, visible classes, and event conditions -->
                    <q-btn
                      flat unelevated
                      color="negative"
                      :disable="!hasAnyFilter"
                      :class="{ 'hidden': !hasAnyFilter }"
                      icon-right="clear"
                      :label="$t('entities.clearQuery')"
                      @click.capture.stop='hasAnyFilter ? clearQuery(true) : null'
                    />
                  </div>
                  <div class="app-entity-preselect-filter-wrapper">
                    <filter-preset
                        :label="$t('entities.preselectFilter.byId')"
                        :snippet="JSON.stringify({'_id': 'placeholder'})"
                        @update:preset="setQuery"
                    />
                    <filter-preset
                        :label="$t('entities.preselectFilter.byIdentifier')"
                        :snippet="JSON.stringify({'identifier': 'placeholder'})"
                        @update:preset="setQuery"
                    />
                    <filter-preset
                        :label="$t('entities.preselectFilter.sinceDate')"
                        :snippet="JSON.stringify({'createdAt': {'$gte': new Date()}})"
                        @update:preset="setQuery"
                    />
                    <filter-preset
                        :label="$t('entities.preselectFilter.betweenDates')"
                        :snippet="JSON.stringify({'createdAt': {'$gte': new Date(), '$lte': new Date()}})"
                        @update:preset="setQuery"
                    />
                  </div>
                </div>
                <q-separator class="q-mt-xs q-mb-sm" />
              </template>
            </json-editor>

            <div class="app-entity-query-control-wrapper q-mt-md q-ml-sm q-ml-auto">
              <q-btn
                class="sq-submit-query-btn app-action-btn"
                :label="$t('entities.submitQuery')"
                :disable="!filters.queryModel"
                unelevated
                size="md"
                @click.capture.stop='doSearch'
              />
            </div>

          </div>

          <div class="q-mt-md">
            <entity-listing
              v-if="hasEntityItems && !loading"
              ref="entityListing"
              :entity-data="entityData"
              :initial-page="filters.page"
              :has-filter="hasFilter"
              :total-items="entityTotalItems"
              :parent-width="parentContainer.width - 24 + 'px'"
              :key="'list' + refreshKeyToken"
              @edited="checkForPendingEdits"
              @page-change="pageChange"
            />
          </div>
        </div>
        <div v-if="!hasEntityItems" class="q-mt-md">
          <h1>{{ $t('entities.titleEmpty') }}</h1>
          <div class="text-center">{{ $t('entities.noEntities') }}</div>
        </div>

        <q-inner-loading :showing="loading">
          <q-spinner
            color="primary"
            size="3em"
            :thickness="3"
          />
        </q-inner-loading>
      </div>
      <div v-if="!($q.screen.xs || $q.screen.sm)" class="app-entity-list-container-wrapper col-12 col-sm-4 col-md-3 col-lg-2">
        <div class="app-entity-list-container">
          <div class="app-entity-list-container-inner">
            <entity-selection
              redirect
              @entity-change="entityChanged"
            />
          </div>
        </div>
      </div>
    </div>

    <entity-refresh-modal
      v-model="showEntityRefreshModal"
      @success="handleRefresh"
    />
    <entity-clear-modal
        :entity="currentEntityName"
        v-model="showEntityClearModal"
        @success="handleClear"
    />
  </q-page>
</template>

<style lang="scss">
  .app-entity-query-editor-wrapper {
    background-color: $background2;
    .app-entity-query-editor {
      margin: 0;
      overflow: visible;
    }
    .sq-submit-query-btn {
      padding: 0 1rem;
    }
  }

  .app-entity-page-container {
    order: 2;
    @media (min-width: $breakpoint-xs) {
      order: unset;
    }
    padding: 1rem .25rem;
    @media (min-width: $breakpoint-xs) {
      padding: 1rem;
    }
  }
  .app-entity-list-container-wrapper {
    order: 1;
    @media (min-width: $breakpoint-xs) {
      order: unset;
    }
    .app-entity-list-container {
      margin: 1.5rem;
      @media (min-width: $breakpoint-xs) {
        position: sticky;
        top: 4rem;
      }
      .app-entity-list-container-inner {
        padding-left: 1rem;
        border-left: $layout-border;
        h2 {
          font-size: .8rem;
          font-weight: 600;
        }
      }
    }
  }
</style>
