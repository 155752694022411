<script>
import { defineComponent } from 'vue';
import { useQuasar } from 'quasar'
import { cacheUsageService } from 'src/services/cacheUsageService'
import LoadingIndicator from 'components/LoadingIndicator.vue'

export default defineComponent({
  name: 'CacheUsageDetails',
  props: {
    flowExecutionId: {
      type: Number,
      required: true,
      default: null
    }
  },
  components: {
    LoadingIndicator,
  },
  setup() {
    const $q = useQuasar();

    return {
      qInstance: $q,
    }
  },
  data() {
    return {
      isLoading: false,
      autoRefreshSeconds: 0,
      autoRefreshing: false,
      autoRefreshOptions: [10, 20, 30],
      autoRefreshLooping: null,
      payload: {
        cacheSizes: {
          formattedCacheMap: [],
          totalSize: '0 byte(s)'
        }
      },
      pagination: {
        sortBy: 'cacheSize',
        decending: true
      },
      searchFilter: '',
      columns: [
        {
          name: 'cacheKey',
          required: true,
          label: this.$t('cacheUsage.detail.tableColumns.cacheKey'),
          align: 'left',
          field: row => row.cacheKey
        },
        {
          name: 'cacheSize',
          required: true,
          label: this.$t('cacheUsage.detail.tableColumns.cacheSize'),
          align: 'right',
          sortable: true,
          field: row => row.cacheSize
        }
      ],
      localFlowExecutionId: null
    }
  },
  methods: {
    loadData() {
      const cacheUsageDetails = cacheUsageService.getCacheUsageDetail(this.flowExecutionId);

      this.isLoading = true;

      cacheUsageDetails.then(res => {
        try {
          this.payload.cacheSizes.formattedCacheMap = res?.data['hydra:member'][2]['formattedCacheMap'];
          this.payload.cacheSizes.totalSize = res?.data['hydra:member'][2]['totalSize'];
        } catch(e) {
          console.error(e);
        }

      }).finally(() => this.isLoading = false);
    },
    refreshData() {
      this.loadData();
    },
    autoRefresh(seconds) {
        clearTimeout(this.autoRefreshLooping);
        this.autoRefreshSeconds = seconds;
        if(seconds > 0) {
          this.autoRefreshing = true;
          this.autoRefreshLoop(seconds);
        } else {
          this.autoRefreshing = false;
        }
      },
      autoRefreshLoop(seconds) {
        if(!this.isLoading) this.refreshData();
        this.autoRefreshLooping = setTimeout(() => {
          if(this.autoRefreshing === true) {
            this.autoRefreshLoop(seconds);
          }
        }, 1000 * seconds);
      },
  },
  beforeUnmount() {
      clearTimeout(this.autoRefreshLooping);
      this.autoRefreshLooping = null;
  },
  created() {
    this.localFlowExecutionId = this.$route.params.flowExecutionId;

    this.loadData();

  }
})

</script>

<template>
  <q-page class="block">
    <div class="q-pa-md relative-position">
      <div class="row justify-between">
        <h1 v-if="localFlowExecutionId">#{{ localFlowExecutionId }} {{ $t('cacheUsage.detail.headline') }}</h1>

        <h1 v-if="payload.cacheSizes.totalSize">{{ $t('cacheUsage.detail.overallTotalSize') }}: {{ payload.cacheSizes.totalSize }}</h1>

      </div>

      <template v-if="!isLoading">
        <div class="cache-table-container row">

          <q-table class="app-cache-usage-table col-12 transparent q-mt-md"
            :rows="payload.cacheSizes.formattedCacheMap"
            :rows-per-page-options="[0]"
            :no-data-label="$t('cacheUsage.noData')"
            :filter="searchFilter"
            :pagination="pagination"
            row-key="index"
            :columns="columns"
            color="primary"
            :hide-bottom="payload.cacheSizes.formattedCacheMap.length > 0 ? true : false"
            :hide-pagination="true"
            flat dense
          >
          <template v-slot:top-right>
            <q-input
              dense outlined
              debounce="300"
              v-model="searchFilter"
              :placeholder="$t('cacheUsage.detail.search')"
            >
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
          </template>

          <template v-slot:top-left>
            <q-btn-dropdown
                split flat dense icon-right="refresh"
                class="app-action-btn justify-end col-auto active"
                :label="$t('cacheUsage.detail.refresh')"
                :title="$t('cacheUsage.detail.refresh')"
                @click='refreshData'
                :disable-main-btn="isLoading"
                :disable-dropdown="isLoading"
            >
              <q-list>
                <q-item :disable="isLoading" :active="autoRefreshSeconds <= 0" clickable v-close-popup @click="autoRefresh(0)">
                  <q-item-section>
                    <q-item-label>{{ $t('cacheUsage.detail.autoRefresh.disable') }}</q-item-label>
                  </q-item-section>
                </q-item>

                <template v-for="seconds in autoRefreshOptions">
                  <q-item :disable="isLoading" :active="autoRefreshSeconds === seconds" clickable v-close-popup @click="autoRefresh(seconds)">
                    <q-item-section>
                      <q-item-label>{{ $t('cacheUsage.detail.autoRefresh.enable', { seconds: seconds }) }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </template>

              </q-list>
            </q-btn-dropdown>
          </template>

          <template v-slot:body-cell-cacheSize="props">
            <q-td :props="props">
              {{props.row.formattedCacheSize}}
            </q-td>
          </template>

          <template v-slot:no-data="{ icon, message, filter }">
            <div class="full-width row flex-center q-gutter-sm">
              <span>
                {{ $t('cacheUsage.noData') }}
              </span>
            </div>
          </template>
        </q-table>
        </div>
      </template>

      <loading-indicator v-if="isLoading" wrapper />


    </div>

  </q-page>
</template>

<style lang="scss" scoped>
.app-cache-usage-table {
  td:first-child {
    max-width: 1rem;
    background-color: $background2;
  }

  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  @media (max-width: $breakpoint-sm) {

    // Attention: max-width, since definition only required for small screen. Default on desktop is good.
    th,
    td {
      padding: 0 .3rem;
    }
  }
}

body.body--dark {
  .app-cache-usage-table {
    td:first-child {
      max-width: 1rem;
      background-color: $dark-page;
    }

    th:first-child,
    td:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
    }
  }
}

.app-cache-list-reload {
    cursor: pointer;
    &:hover {
      color: $secondary;
    }
  }
</style>
