import { authHeader } from '../helpers/auth-header';
import apiUrl from '../helpers/api-url';
import client from "src/helpers/authenticated-axios";

export const authService = {
  login,
  expired,
  logout,
  requestReset,
  setPassword,
  getUser
};

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password })
  };
  return fetch(apiUrl() + `/login_check`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
      }
      return user;
    });
}

/* @TODO handle response */
function requestReset(username, callback) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username })
  };
  return fetch(apiUrl() + `/users/reset_password`, requestOptions)
    .then(response => {
      callback(response);
      return response;
    });
}


/* @TODO handle response */
function setPassword(userId, hash, newPassword, callback) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ resetToken: hash, password: newPassword })
  };
  return fetch(apiUrl() + `/users/${userId}/reset_password`, requestOptions)
    .then(response => {
      callback(response)
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

function expired() {
  logout();
  location.href = "/#/login"; //Backlog, cs: Use router instead of plain location href + check security
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function getUser(callback) {
  client().get('/user/info').then((response) => {
    callback(response.data)
  }).catch((error) => {
    callback(error);
  });
}
