<script>
import { mapActions, mapState } from 'vuex'
import { debounce, isEqual } from 'lodash'
import { required } from 'pages/JsonToForm/Helpers/validations'

  export default {
    name: 'TabLabelGenerator',

    props: {
      modelValue: {
        required: false
      },

      disable: {
        type: Boolean,
        required: false,
        default: false
      },

      parentTabLabels: {
        required: false
      },

      rules: {
        type: Array,
        required: false
      }
    },

    emits: [
      'update:options',
      'update:model-value',
      'has-error'
    ],

    data() {
      return {
        value: this.modelValue || []
      }
    },

    computed: {
      ...mapState('formBuilder', ['tabLabelOptions', 'labelUseCounter']),

      selectableOptions() {
        return this.tabLabelOptions.filter(label => !this.parentTabLabels?.includes(label))
      }
    },

    methods: {
      required,

      ...mapActions('formBuilder', ['updateTabLabelOptions', 'checkToRemoveTabLabel']),

      handleNewValue(value, done) {
        done(value, 'add-unique')

        this.$emit('update:options', {
          options: this.tabLabelOptions,
          currentValue: this.value
        })
      },

      handleModelChange: debounce(function () {
        this.$emit('update:model-value', this.value)
      }, 500),

      handlePopupShow() {
        this.$refs.selector.setOptionIndex(-1)
      },

      updateOptions(values) {
        this.updateTabLabelOptions(values)
      },

      handleRemove({ value }) {
        const index = this.value.findIndex(val => val === value)

        if (index > -1) {
          this.value.splice(index, 1)
          this.checkToRemoveTabLabel(value)

          this.handleModelChange()
        }
      },

      handleAdd({ value }) {
        this.$refs.selector.setOptionIndex(-1)
        this.$refs.selector.updateInputValue('')
        this.updateOptions([value])
      },

      handleParentLabelUpdate(oldParentLabels) {
        this.value = this.value.filter(label => {
          if (oldParentLabels?.includes(label)) {
            this.checkToRemoveTabLabel(label)

            return false
          }

          return true
        })

        this.updateOptions(this.value)
        this.handleModelChange()
      },

      validate(value) {
        const validated = required(this.$t('jsonToForm.formBuilder.validation.isRequired', { field: this.$t('jsonToForm.formBuilder.form.tabLabel', 2) }, 2))(value)

        this.$emit('has-error', !!validated)

        return validated
      }
    },

    watch: {
      modelValue(value) {
        if (!isEqual(value, this.value)) {
          this.value = value
          this.updateOptions(this.value)
        }
      },

      parentTabLabels: {
        handler(value, oldValue) {
          if (!isEqual(oldValue, value)) {
            this.handleParentLabelUpdate(oldValue)
          }
        },

        immediate: true,
        deep: true
      }
    }
  }
</script>

<template>
  <div>
    <q-select
      ref="selector"
      v-model="value"
      :label="`* ${$t('jsonToForm.formBuilder.form.tabLabel', 2) }`"
      clearable
      dense outlined
      options-dense
      filled
      emit-value
      map-options
      use-input
      multiple
      hide-selected
      use-chips
      :disable="disable"
      new-value-mode="add-unique"
      :options="selectableOptions"
      :rules="[validate]"
      class="q-py-sm"
      @update:model-value="handleModelChange"
      @new-value="handleNewValue"
      @popup-show="handlePopupShow"
      @add="handleAdd"
    >
      <template
        v-if="value?.length"
        #prepend
      >
        <div class="selected-item-container">
          <q-chip
            v-for="item in value"
            dense
            removable
            @remove="handleRemove({ value: item })"
          >
            {{ item }}
          </q-chip>
        </div>
      </template>
    </q-select>
  </div>
</template>

<style scoped>
  :deep(.selected-item-container) {
    border-right: 1px solid #303030;
  }
</style>
