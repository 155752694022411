<script>
import { defineComponent } from 'vue'
import LoadingIndicator from 'components/LoadingIndicator.vue'
import DateDisplay from 'components/DateDisplay.vue'
import StatusBadge from "components/StatusBadge.vue";

export default defineComponent({
  name: 'FlowMeta',
  props: {
    currentFlow: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  components: {
    LoadingIndicator,
    DateDisplay,
    StatusBadge
  }
})
</script>

<template>
  <loading-indicator v-if="isLoading" wrapper />
  <div v-if="!isLoading" class="q-mt-sm q-mr-md">
    <div class="row q-col-gutter-lg q-col-gutter-y-sm">
      <div class="row items-baseline q-ml-none">
        <span class="app-text-weight-semibold q-mr-sm">{{ $t('flow.executions.createdAt') }}:</span>
        <div v-if="currentFlow.created_at" class="column">
          <date-display :start-time="currentFlow.created_at" only-date />
          <date-display :start-time="currentFlow.created_at" />
        </div>
        <div v-else> - </div>
      </div>
      <div class="row items-baseline">
        <span class="app-text-weight-semibold q-mr-sm">{{ $t('flow.executions.updatedAt') }}:</span>
        <div v-if="currentFlow.updated_at" class="column">
          <date-display :start-time="currentFlow.updated_at" only-date />
          <date-display :start-time="currentFlow.updated_at" />
        </div>
      <div v-else> - </div>
      </div>
      <div>
        <span class="app-text-weight-semibold q-mr-sm">{{ $t('flow.executions.duration') }}:</span>
        <date-display :start-time="currentFlow.created_at" :end-time="currentFlow.updated_at" />
      </div>
      <div>
        <span class="app-text-weight-semibold q-mr-sm">{{ $t('flow.executions.processStep') }}:</span>{{ currentFlow.currentProcessStepIdentifier }}
      </div>
      <div>
        <span class="app-text-weight-semibold q-mr-sm">{{ $t('flow.executions.flow') }}:</span>#{{ currentFlow.id }}: {{ currentFlow.flowName }}
      </div>
      <div>
        <status-badge
          :running="Boolean(currentFlow.active)"
          :status="currentFlow.success"
          :running-tooltip="$t('flow.status.runningTooltip')"
          :positive-tooltip="$t('flow.status.positiveTooltip')"
          :negative-tooltip="$t('flow.status.negativeTooltip')"
        />
      </div>
    </div>
  </div>
</template>

<style>
.app-flow-status-action-bar {
  margin-left: -24px !important;
  margin-right: -24px !important;
}
</style>
