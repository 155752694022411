<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex';
import { ACCEPTED_BASE_PATHS } from 'pages/Browser/template';
import { notifyError } from 'src/utils/notify';
import { fileSystemService } from 'src/services'
import JsonEditor from 'components/JsonEditor.vue'
import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent({
  name: 'FilesystemModal',

  components: {
    SqDialog,
    JsonEditor
  },

  props: {
    filesystem: {
      type: Object
    }
  },

  emits: ['saved'],

  data() {
    return {
      filesystemName: '',
      filesystemJsonConfig: '',
      isLoading: false,
      errorBag: {},

      showValidationErrorBanner: false,
      errorMessage: this.$t('browser.modal.invalidInfo'),

      ACCEPTED_BASE_PATHS
    }
  },

  methods: {
    ...mapActions('fileSystem', ['fileSystemLoad']),

    async createFilesystem () {
      try {
        this.isLoading = true;

        const response = await fileSystemService.addFilesystem(this.filesystemName, this.filesystemJsonConfig)
        if (response.data?.success ?? response.data?.identifier) {
          this.$store.dispatch('alert/success', "browser.modal.createFilesystemSuccess", { root: true });
          await this.fileSystemLoad();
          this.$emit('saved')
        }
      } catch (error) {
        this.errorBag = error.response?.data?.errors ?? {};
        const status = error.response.status;
        notifyError(status === 422 ?
          this.$t('browser.modal.invalidInfo') :
          this.$t('browser.modal.fileSystemCreationFailed')
        );

        if (this.errorBag['config.adapterConfig.path']) {
          this.showPathNotAllowedError();
        }
      } finally {
        this.isLoading = false;
      }
    },

    async updateFilesystem () {
      try {
        this.isLoading = true;

        const response = await fileSystemService.editFilesystem(this.filesystem.identifier, this.filesystemName, this.filesystemJsonConfig)

        if (response.data?.success ?? response.data?.identifier) {
          this.$store.dispatch('alert/success', "browser.modal.editFilesystemSuccess", { root: true });
          await this.fileSystemLoad();
          this.$emit('saved')
        }
      } catch (error) {
        const status = error.response.status;
        notifyError(status === 422 ?
          this.$t('browser.modal.invalidInfo') :
          this.$t('browser.modal.fileSystemCreationFailed')
        );
      } finally {
        this.isLoading = false;
      }
    },

    saveFilesystem() {
      !this.filesystem?.identifier ? this.createFilesystem() : this.updateFilesystem();
    },

    async validate () {
      this.errorBag = {};
      this.showValidationErrorBanner = false;
      this.errorMessage = this.$t('browser.modal.invalidInfo');

      const isValid = await this.$refs.filesystemForm.validate();

      if (!isValid) {
        notifyError(this.$t('browser.modal.invalidInfo'));
        return;
      }

      const json = JSON.parse(this.filesystemJsonConfig);
      const adapter = json?.adapter;

      if (adapter && adapter !== 'local') {
        this.saveFilesystem();
        return;
      }

      const isPathValid = this.validatePath(json?.adapterConfig?.path ?? '');

      if (isPathValid) this.saveFilesystem();
    },

    validatePath (path) {
      try {
        let isAllowedBasePath = false;

        for (const basePath of ACCEPTED_BASE_PATHS) {
          if (path.startsWith(basePath)) {
            const nextChar = path.slice(basePath.length, basePath.length + 1);
            if (nextChar === '' || nextChar === '/') {
              isAllowedBasePath = true;
              break;
            }
          }
        }

        if (!isAllowedBasePath) {
          this.showPathNotAllowedError();
          return false;
        }

        return isAllowedBasePath;
      } catch (error) {
        this.showValidationErrorBanner = true;
        this.errorMessage = this.$t('browser.modal.invalidJson', {error});
      }
    },

    showPathNotAllowedError () {
      this.showValidationErrorBanner = true;
      this.errorMessage = this.$t('browser.modal.invalidPath', {acceptedPaths: ACCEPTED_BASE_PATHS.join(', ')});
    },

    getJson() {
      try {
        this.filesystemJsonConfig = JSON.stringify(this.$props.filesystem.config);
      } catch(e) {}
    },

    initialize() {
      if(!this.$props.filesystem) this.filesystemJsonConfig = "{\"adapter\":\"local\",\"adapterConfig\":{\"path\":\"/opt/synqup-media\"},\"publicUrlPrefix\":null}";
      this.filesystemName = this.$props?.filesystem?.identifier;
      if(this.$props.filesystem) this.getJson();
    },

    resetState() {
      this.isLoading = false
      this.filesystemName = ''
      this.filesystemJsonConfig = ''
    },

    isEmpty (val, castAsBool) {
      const CONDITION = val?.length <= 0 || typeof val === 'undefined';
      if(castAsBool) return !!CONDITION;
      if (CONDITION) {
        return this.$t('browser.modal.nameRequired');
      }
    },

    hasForbiddenChar (val, castAsBool) {
      const REGEX = new RegExp('^[a-zA-Z0-9_.-]+$')
      const CONDITION = !REGEX.test(val);
      if(castAsBool) return !!CONDITION;
      if(CONDITION) {
        return this.$t('browser.modal.forbiddenChar');
      }
    }
  }
})
</script>

<template>
  <sq-dialog
    :type="filesystem ? 'update' : 'create'"
    :save-button-label="$t('browser.modal.editFilesystem')"
    :loading="isLoading"
    size="md"
    @save="validate"
    @hide="resetState"
    @show="initialize"
  >
    <template #title>
      {{ filesystem ? $t('browser.editFilesystem') : $t('browser.addFilesystem') }}
    </template>

    <template #content>
      <q-form ref="filesystemForm">
        <q-banner
          v-if="showValidationErrorBanner"
          inline-actions
          class="text-white bg-red q-animate--fade"
        >
          {{ errorMessage }}
          <template #action>
            <q-btn
              flat
              color="white"
              label="Close"
              @click="showValidationErrorBanner = false"
            />
          </template>
        </q-banner>

        <q-input
          v-model="filesystemName"
          dense
          outlined
          lazy-rules="ondemand"
          :label="$t('browser.modal.namePlaceholder')"
          :rules="[isEmpty, hasForbiddenChar]"
          :error="!!errorBag.identifier"
          :error-message="errorBag.identifier"
          data-cy="filesystemNameInput"
          class="app-create-filesystem-name-input q-ml-xs q-mt-sm"
        />

        <div class="text-h6 q-mt-md">
          {{ $t('browser.modal.jsonConfigHeadline') }}
          <q-icon name="info">
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              class="app-tooltip-mobile"
            >
              <div>
                {{ $t('browser.modal.acceptedPathsPrefix') }}
              </div>

              <div v-for="(path, index) in ACCEPTED_BASE_PATHS" :key="index">
                {{ path }}
              </div>

              <div class="q-mt-sm">
                {{ $t('browser.modal.createSubpathHint', {path: '/opt/synqup-media/subfolder'}) }}
              </div>
            </q-tooltip>
          </q-icon>
        </div>
        <json-editor
          v-model="filesystemJsonConfig"
          wrapped
          class="q-mt-none"
          init-timeout="300"
        />
      </q-form>
    </template>
  </sq-dialog>
</template>

<style lang="scss">
  @media (min-width: $breakpoint-xs) {
    .app-create-filesystem-name-input {
      width: 50%;
    }
  }
</style>
