<script>
import { defineComponent, ref } from 'vue'
import LoadingIndicator from "components/LoadingIndicator";
import Logo from "pages/Auth/components/Logo.vue";

  export default defineComponent({
    name: 'LoginForm',
    components: {
      Logo,
      LoadingIndicator
    },
    data () {
      return {
        username: '',
        password: ref(''),
        isPwd: ref(true),
      }
    },
    computed: {
      loggingIn () {
        return this.$store.state.authentication.status.loggingIn;
      }
    },
    methods: {
      handleSubmit () {
        const { username, password } = this;
        let redirect = "";
        if(this.$router.currentRoute._value.redirectedFrom) {
          redirect = this.$router.currentRoute._value.redirectedFrom.fullPath;
        }
        const { dispatch } = this.$store;
        if (username && password) {
          dispatch('authentication/login', { username, password, redirect });
        }
      }
    }
  })
</script>

<template>
  <form class="app-login-form" @submit.prevent="handleSubmit">
    <div class="q-pa-md q-pa-md flex justify-center items-end">
      <h1 class="col-12 col-xs">{{ $t('publicPages.login.headline') }}<sup class="q-mr-xs-lg">&reg;</sup></h1>
      <logo mobile />
    </div>
    <!---->
    <div class="q-pa-md">
      <div class="app-login-input-wrapper q-gutter">
        <q-input
          required
          dense outlined
          class="app-login-input-name q-pa-sm"
          v-model="username"
          :label="$t('publicPages.login.inputNameLabel')"
          data-cy="usernameInput"
          autocomplete="username"
        >
          <template v-slot:prepend>
            <q-icon class="q-pl-md" name="person" />
          </template>
        </q-input>
        <q-input
          required
          dense outlined
          class="app-login-input-password q-pa-sm"
          v-model="password"
          :label="$t('publicPages.login.inputPasswordLabel')"
          :type="isPwd ? 'password' : 'text'"
          data-cy="passwordInput"
          autocomplete="current-password"
        >
          <template v-slot:prepend>
            <q-icon class="q-pl-md" name="lock" />
          </template>
          <template v-slot:append>
            <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
            />
            <q-tooltip anchor="top middle" self="bottom middle" class="app-tooltip-mobile" v-if='$q.platform.is.mobile'>{{ isPwd ? $t('general.showPassword') : $t('general.hidePassword') }}</q-tooltip>
          </template>
        </q-input>
        <div class="app-password-recover q-pt-md flex justify-end q-mr-sm">
          <a href="/#/passwordrecover">{{ $t('publicPages.login.passwordRecover') }}</a>
        </div>
      </div>
    </div>
    <div class="q-pa-md relative-position">
      <loading-indicator v-if="loggingIn" />
      <q-btn flat class="app-action-btn sq-login-btn" :label="$t('publicPages.login.submit')" :disabled="loggingIn" type="submit" data-cy="loginButton" />
    </div>
  </form>
</template>

<style lang="scss">
  .app-login-form {
    position: relative;
    z-index: 1;
  }
  .app-login-input-wrapper {
    margin: -1.25rem;
    .q-field__control {
      &::before, &::after {
        left: 1rem;
      }
    }
  }
  .app-password-recover {
    text-decoration: underline;
  }
  .app-login-input-password, .app-login-input-name, .app-password-recover {
    max-width: 400px;
  }
  .sq-login-btn {
    padding: 0 1rem;
  }
</style>
