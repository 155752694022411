<script>

export default {
  name: "CustomChartLegend",

  setup() {
    const columns = [
      {
        name: 'legend',
        field: (row) => row.color,
        label: '',
        align: 'left',
        style: 'width: 20px',
        classes: 'ellipsis'
      }
    ]
    return {
      columns
    }
  },

  props: {
    legendData: {
      type: Array,
      default: false
    }
  },

  methods: {
    /**
     * Needed to parse for ellipsis as it can't be supported inside quasar table's flex
     */
    parseLegend() {
      let data = this.$props.legendData;

      data.forEach((value, index) => {
        if (value.description.length > 40) {
          this.$props.legendData[index].parsedDescription = value.description.slice(0, 40) + '…';
        }
      });
    }
  },

  created() {
    this.parseLegend()
  },

  watch: {
    legendData() {
      this.parseLegend()
    }
  }

}
</script>

<template>
    <q-table
      class="custom-chart-legend"
      :rows="legendData"
      :columns="columns"
      hide-header
      hide-bottom
    >
      <template #body-cell-legend="scope">
        <q-tr class="q-tr--no-hover">
          <q-td class="q-td--no-hover" :props="scope">
            <q-badge :style="'background:' + scope.row.color"/>
            <template v-if="scope.row.url">
              <a :href="scope.row.url">
                <q-badge
                  outline
                  :style="'color:' + scope.row.color + '; margin-left: 5px'"
                  :title="scope.row.description"
                  :label="scope.row.parsedDescription ?? scope.row.description"
                />
              </a>
            </template>

            <template v-else>
              <q-badge
                outline
                :style="'color:' + scope.row.color + '; margin-left: 5px'"
                :title="scope.row.description"
                :label="scope.row.parsedDescription ?? scope.row.description"
              />

                <q-tooltip
                  anchor="top middle" self="bottom middle"
                  v-if='$q.platform.is.mobile'
                  class="app-tooltip-mobile"
                >
                  {{ scope.row.description }}
                </q-tooltip>
            </template>
          </q-td>
        </q-tr>
      </template>
    </q-table>
</template>

<style lang="scss">

  .custom-chart-legend {
    box-shadow: none !important;

    .q-table__card {
      box-shadow: none !important;
    }

    .q-table {
      background: none !important;
      border: none !important;
    }

    .q-table thead, .q-table tr, .q-table th, .q-table, td {
      border: none !important;
    }

    // working solution for the charts without touching tbody / chart template
    @media (max-width: $breakpoint-lg) {
      tbody {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }

  }

  body.body--dark {
    .custom-chart-legend {
      .q-badge--outline {
        background: $background2;
      }
    }
  }

</style>
