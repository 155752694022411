<script>
  import { defineComponent, ref } from 'vue'
  import { mapGetters } from 'vuex'
  import LoadingIndicator from 'components/LoadingIndicator'
  import DateDisplay from 'components/DateDisplay.vue'
  import StatusBadge from "components/StatusBadge.vue";

  export default defineComponent({
    name: "MessageQueue",
    components: {
      StatusBadge,
      DateDisplay,
      LoadingIndicator
    },
    props: {
      col: {
        type: String,
        required: false,
        default: ''
      }
    },
    setup () {
      return {
        pagination: {
          rowsPerPage: 0 // 0 = show all
        }
      };
    },
    computed: {
      ...mapGetters('messageQueues', [
        'isLoading',
        'isRefreshing',
        'messageQueue',
        'queueRows',
        'queueColumns'
      ]),
    },
    mounted() {
      this.$store.dispatch('messageQueues/getMessageQueue')
    },
    methods: {
      refreshMessageQueues() {
        this.$store.dispatch('messageQueues/getMessageQueue');
      }
    }
  });
</script>
<template>
  <div class="flex justify-between q-pt-md self-stretch relative-position" :class="col">
    <q-card class="app-message-queue full-width" :class="col" flat>
      <loading-indicator v-if="isLoading" />
      <template v-else>
        <q-icon size="3rem" name="forum" class="app-card-bg-icon"></q-icon>
        <q-item>
          <q-item-section>
            <q-item-label class="app-message-queue-label app-text-weight-semibold">{{ $t('dashboard.messageQueue.headline') }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-card-section class="q-py-none" horizontal>
          <q-card-section class="app-message-queue-value col q-py-none q-ml-sm" data-cy="messageQueueValue">
            <div class="flex full-width">
              <div class="q-mr-xl full-width">
                <q-table dense flat
                         :rows="queueRows"
                         :columns="queueColumns"
                         :row-key="'queue'"
                         class="full-width"
                         :pagination.sync="pagination"
                         hide-bottom
                >
                  <template #body-cell-status="scope">
                    <td class="text-left">
                      <status-badge v-if="!isLoading"
                                    :running="Boolean(scope.value.toLowerCase() === 'running') && scope.row.messages > 0 "
                                    :status="scope.value.toLowerCase()"
                                    :positive-tooltip="$t('dashboard.messageQueue.status.positiveTooltip')"
                                    :negative-tooltip="$t('dashboard.messageQueue.status.negativeTooltip')"
                                    :running-tooltip="$t('dashboard.messageQueue.status.runningTooltip')"
                      />
                      <!-- TODO: CHECK scope.row.messages > 0 later after clarification with backend // 07.03. CS && CL -->
                    </td>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card-section>
        <q-card-section class="app-message-queue-action col-auto q-pl-none q-mb-sm q-mx-sm">
          <div class="flex justify-end">
            <q-btn flat dense no-caps icon-right="refresh" class="q-pr-sm justify-end app-action-btn"
                   :label="$t('dashboard.messageQueue.refresh')"
                   @click.capture.stop="refreshMessageQueues()"
                   data-cy="messageQueueRefreshButton"
            />
          </div>
        </q-card-section>
      </template>
    </q-card>
  </div>
</template>
