<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'FilterPreset',
    emits: ['update:preset'],
    props: {
        snippet: {
          type: String,
          required: true
        },
        label: {
          type: String,
          required: true
        }
    },
    methods: {
      addPreset(p) {
        this.$emit('update:preset', p);
      }
    }
  })
</script>

<template>
  <q-btn
    :label="this.label"
    @click="this.addPreset(this.snippet)"
    class="q-mr-sm q-mb-sm"
    flat dense
    unelevated
  />
</template>
