<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { fileNameRules } from 'src/helpers/name-validator'
import JsonEditor from 'components/JsonEditor.vue'

import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent({
  name: 'AddFileModal',
  components: {
    SqDialog,
    JsonEditor
  },

  props: {
    path: {},
    base64Encode: {}
  },

  emits: ['created'],

  data () {
    return {
      currentTab: 'create',
      fileName: '',
      fileUrl: '',
      fileContent: '',
      isLoading: false
    }
  },

  computed: {
    ...mapGetters('fileManager', [
      'isRefreshing'
    ]),
    fileExtension() {
      if (!this.fileName || this.fileName === '') return 'text'; // Default to text
      return this.fileName.substr(this.fileName.lastIndexOf(".") + 1)?.toLowerCase();
    },
  },

  methods: {
    fileNameRules (val, castAsBool) {
      return fileNameRules(val, castAsBool, this, true)
    },
    resetState() {
      this.currentTab = 'create'
      this.fileName = ''
      this.fileUrl = ''
      this.fileContent = ''
      this.isLoading = false
    },
    addFile() {
      this.isLoading = true

      if (this.currentTab === "create") {
        this.$store.dispatch('fileManager/addFile', {path: this.path, file: {name: this.fileName, content: this.base64Encode(this.fileContent)}});
      } else if(this.currentTab === "upload") {
        this.$store.dispatch('fileManager/addFileFromUrl', {path: this.path, file: {name: this.fileName, url: this.fileUrl}});
      }

      // we don't currently have the ability to track the status of the API request. So to mitigate the
      // problem and make sure we display something to our users are running something in the background
      // we set a valid timeout time for the UI to act something
      setTimeout(() => {
        this.isLoading = false
        this.$emit('created')
      } ,1000)
    },
  }
})
</script>

<template>
  <sq-dialog
    type="create"
    :disabled="!fileName || (currentTab === 'upload' && !this.fileUrl)"
    :save-button-label="$t('browser.modal.createFilesystem')"
    :loading="isLoading"
    size="md"
    @save="addFile"
    @hide="resetState"
  >
    <template #title>
      {{ $t('browser.newFile.headline') }}
    </template>

    <template #content>
      <q-input class="app-create-file-name-input q-mx-md q-mt-md q-mb-md"
               dense outlined
               v-model="fileName"
               type="text"
               :label="$t('browser.newFile.nameLabel')"
               :placeholder="$t('browser.newFile.namePlaceholder')"
               :rules="[fileNameRules]"
               data-cy="fileNameInput"
      />

      <q-tabs v-model="currentTab" align="left" dense active-color="primary" indicator-color="primary" narrow-indicator>
        <q-tab name="create" :label="$t('browser.newFile.tab.create')" :ripple="false" data-cy="createFileTab" />
        <q-tab name="upload" :label="$t('browser.newFile.tab.uploadFromUrl')" :ripple="false" data-cy="uploadFileTab" />
      </q-tabs>
      <q-tab-panels v-model="currentTab" animated>
        <q-tab-panel name="create" class="q-pa-md">
          <div class="text-h6 q-mt-md">{{ $t('browser.newFile.fileContentHeadline') }}</div>
          <json-editor
            :lang="fileExtension"
            v-model="fileContent"
            :no-validation="true"
            wrapped
            data-cy="fileContentInput"
          />
        </q-tab-panel>
        <q-tab-panel name="upload" class="q-pa-md">
          <q-input class="app-upload-file-from-url-input q-ml-xs q-mt-sm"
                   outlined
                   v-model="fileUrl"
                   :label="$t('browser.newFile.urlLabel')"
                   :placeholder="$t('browser.newFile.urlPlaceholder')"
                   :rules="[val => !!val || $t('browser.newFile.urlRequired')]"
                   data-cy="fileURLInput"
          />
        </q-tab-panel>
      </q-tab-panels>
    </template>
  </sq-dialog>
</template>
