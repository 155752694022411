<script>
import {defineComponent, onBeforeUnmount, ref} from 'vue'
import { checkRunningFlowLoop, clearLooping } from 'src/helpers/auto-refresh'
import {flowExecutionService, flowService} from "src/services";

import LoadingIndicator from 'components/LoadingIndicator.vue'
import StatisticCard from 'components/StatisticCard.vue'
import DateDisplay from 'components/DateDisplay.vue'
import JdmItem from './components/JdmItem/JdmItem.vue'
import JsonEditor from 'components/JsonEditor.vue'
import CreateJobDispatcherMappingModal from './components/JobDispatcherMappingModal/CreateJobDispatcherMappingModal'
import ExportFlowModal from '../Overview/components/FlowModal/ExportFlowModal'
import EndFlowModal from '../Overview/components/FlowModal/EndFlowModal'
import FlowModal from '../Overview/components/FlowModal/FlowModal'
import FlowItemDetails from '../Overview/components/FlowItemDetails'
import StatusBadge from "components/StatusBadge.vue";
import usePaginator from "src/composables/usePaginator";
import Paginator from "components/Paginator.vue";

export default defineComponent({
  name: 'Detail',

  components: {
    Paginator,
    StatusBadge,
    FlowModal,
    EndFlowModal,
    LoadingIndicator,
    StatisticCard,
    DateDisplay,
    JdmItem,
    ExportFlowModal,
    JsonEditor,
    FlowItemDetails,
    CreateJobDispatcherMappingModal
  },

  setup () {
    const { pagination, readFromQuery, updateRouteQuery, clearQuery } = usePaginator();
    return {
      pagination,
      readFromQuery,
      updateRouteQuery,
      clearQuery,
      // ref documentation: https://quasar.dev/start/how-to-use-vue#handling-vue-properties
      textareaInput: ref('')
    }
  },

  data () {
    onBeforeUnmount(() => {
      clearLooping(this.autoRefreshLooping);
    });
    return {
      showJdmModal: false,
      showFlowModal: false,
      showEndFlowModal: false,
      showExportFlowModal: false,
      configurationModel: this.configuration,
      originalConfiguration: null,
      configurationKey: 0,
      currentFlow: null,
      getMappingsOfProcessStep: (processStep) => {
        if (this.currentFlow && ('jobDispatcherMappings' in this.currentFlow)) {
          return this.currentFlow.jobDispatcherMappings.filter(jdm => jdm.processStepIdentifier === processStep)
        }
        return []
      },
      autoRefreshLooping: null,
      runningFlow: null,
      configuration: null,
      isRunning: null,
      isLoading: true,
      validJson: null,
      flowBlocked: false,
      processStep: ref(),
      expandConf: ref(),
      expandInput: ref(),
      expandTransform: ref(),
      expandOutput: ref(),
      filterNoItems: false,
      showFlowExecutionList: false,
      currentTab: 'jdms',
      columns: [
        {
          name: 'id',
          required: true,
          label: this.getTranslation("id"),
          align: 'left',
          field: row => row.id
        },
        {
          name: 'success',
          required: false,
          label: this.getTranslation("success"),
          align: 'left'
          //field: row => this.renderSuccessCol(row.success, row.active)
        },
        {
          name: 'processStep',
          required: true,
          label: !(this.$q.screen.xs || this.$q.screen.sm) ? this.getTranslation("processStep") : this.getTranslation("processStepShort"),
          align: 'left',
          field: row => row.currentProcessStepIdentifier
        },
        {
          name: 'duration',
          required: false,
          label: this.getTranslation("duration"),
          align: 'left'
          // field content is defined via slot in template
        },
        {
          name: 'created',
          required: false,
          label: this.getTranslation("createdAt"),
          align: 'left'
          // field content is defined via slot in template
        },
        {
          name: 'time',
          required: false,
          label: this.getTranslation("time"),
          align: 'left'
          // field content is defined via slot in template
        },
        {
          name: 'link',
          required: false,
          label: this.getTranslation("link")
          // field content is defined via slot in template
        }
      ],
      items: []

    }
  },

  computed: {
    getInputMappings() {
      return this.getMappingsOfProcessStep('input')
    },

    getTransformationMappings() {
      return this.getMappingsOfProcessStep('transformations')
    },

    getOutputMappings() {
      return this.getMappingsOfProcessStep('output')
    },
    configurationEmpty() {
      return !this.configurationModel || this.configurationModel === '';
    },
    configurationChanged() {
      return this.configurationModel !== this.originalConfiguration &&
        !(this.originalConfiguration === null || typeof this.originalConfiguration === 'undefined');
    },
    configurationValid() {
      return this.validJson;
    }
  },

  methods: {
    getTranslation(key) {
      return this.$t('flow.executions.thLabel.' + key);
    },


    pageLoad(payload = null, softReload = false) {
      if(!softReload) this.isLoading = true;
      const getData = () => {
        flowService.getFlowDetails(this.$route.params.id, (data) => {
          this.isRunning = data.isRunning;
          let flowRunning = false;
          if(data['@type'] === "hydra:Error") {
            this.$router.push({
              name: 'ERROR',
              params: { n: true }
            })
          } else {
            if(this.originalConfiguration === null || typeof this.originalConfiguration === 'undefined') this.originalConfiguration = JSON.stringify(data.config, null, 2);
            if(data.isRunning === true) flowRunning = true;
            else {
              // Break loop check once flow is finished
              clearLooping(this.autoRefreshLooping);
            }
            this.currentFlow = data;
            if(!softReload) this.configuration = data.config; // Only necessary for init
          }
          this.runningFlow = flowRunning;
          if(this.runningFlow === true) this.autoRefreshLooping = checkRunningFlowLoop(this.runningFlow, () => this.pageLoad(null, true));
          this.isLoading = false;
        })



        // ----------------------------------------------------------------------------------------------------------------------------------------------------
        flowExecutionService.getFlowExecutions(this.pagination.page, this.pagination.itemsPerPage, null, `&flow.id[]=${this.$route.params.id}`, (data) => {
          this.pagination.totalItems = data['hydra:totalItems'] ?? 0
          this.pagination.pageCount = Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
          this.items = data['hydra:member'] ?? []
          this.items.forEach(member => member.active ? this.runningFlow = true : null)

          const flows = data['hydra:member'];
          if(flows?.length > 0 ) {
            let foundRunningFlow = false;
            for (const flow of flows) {
              if(flow.active === true) {
                foundRunningFlow = true;
                break;
              }
            }
            this.runningFlow = foundRunningFlow;
            if(!foundRunningFlow) clearLooping(this.autoRefreshLooping);

            this.isLoading = false;
            this.filterNoItems = (data['hydra:member'] === 0) ? 1 : 0;
            if (this.runningFlow) this.autoRefreshLooping = checkRunningFlowLoop(this.runningFlow, () => this.pageLoad(true));
          }
        })
        // ----------------------------------------------------------------------------------------------------------------------------------------------------


      }
      if(typeof payload?.deleted !== "undefined") {
        flowService.deleteJobDispatcherMapping(payload.jdmId, (data) => {
          this.isLoading = false;
          if(data?.data['@type'] === "hydra:Error") {
            this.$store.dispatch('alert/error', 'flow.overview.detail.modal.jdmDeletionFailed');
          } else {
            getData();
            this.$store.dispatch('alert/success', 'flow.overview.detail.modal.jdmDeletionSuccess');
          }
        });
      } else {
        getData();
      }
    },

    resetPageAndReload() {
      this.pagination.page = 1
      this.pageLoad()
    },

    startFlow() {
      const activeJdms = this.currentFlow.jobDispatcherMappings.some(e => e.active === true);

      if (!activeJdms) {
        this.$store.dispatch('alert/error', "flow.noActiveJdmError", { root: true });
        return;
      }

      this.flowBlocked = true;
      flowService.startFlow(this.currentFlow.id, (data) => {
        if(data.success !== 0) {
          this.$store.dispatch('alert/success', "flow.flowStartedSuccess", { root: true });
          this.pageLoad();
        } else {
          this.$store.dispatch('alert/error', data.msg, { root: true });
        }
        this.flowBlocked = false;
      })
    },

    handleFlowEnded() {
      this.showEndFlowModal = false
      this.pageLoad()
    },

    createJdmModal(step) {
      this.processStep = step;
      this.showJdmModal = true
    },

    handleJdmCreated() {
      this.showJdmModal = false
      this.pageLoad()
    },

    updateFlowConfig() {
      try {
        const parsedConfig = JSON.parse(this.configurationModel);
        this.isLoading = true;
        flowService.updateFlowConfig({ flowId: this.$route.params.id, config: parsedConfig}, (data) => {
          if (data.id) {
            this.$store.dispatch('alert/success', "flow.overview.detail.configuration.updateSuccess", { root: true });
            this.originalConfiguration = this.configurationModel;
            this.configurationKey += 1; // Necessary for editor model view update - automatically triggers on key change
          } else {
            this.$store.dispatch('alert/error', "flow.overview.detail.configuration.updateFail", { root: true });
          }
          this.isLoading = false;
        })
      } catch(e) {
        this.$store.dispatch('alert/error', "flow.overview.detail.configuration.updateFailJsonSyntax");
      }
    },
    updateConfigurationEditor() {
      this.configurationModel = (typeof this.configuration !== "undefined" && this.configuration !== null) ? JSON.stringify(this.configuration, null, 2) : "{}";
      this.configurationKey += 1; // Necessary for editor model view update - automatically triggers on key change
    },

    handleUpdated() {
      this.showFlowModal = false
      this.pageLoad()
    },

    checkValidJson(valid) {
      this.validJson = valid;
    },

    goToDetail (evt, row, newTab) {
      const url = '/flowexecutions/detail/' + row.id
      if (newTab) window.open('#' + url, '_blank').focus();
      else this.$router.push(url);
    }
  },
  mounted() {
    this.pageLoad();
  },
  watch: {
    configuration: function() {
      this.updateConfigurationEditor();
    }
  }
})
</script>

<template>
  <q-page class="block app-flow-detail">
    <div>
      <div class="app-container-wrapper">
        <div class="app-headline-container q-pa-md flex row" v-if="currentFlow !== null">
          <div class="full-width flex justify-between items-center">
              <h1 class="q-mr-sm">{{ currentFlow.name }}</h1>

              <div class="controls q-my-md q-my-lg-none q-mb-xs-lg">

                <q-btn v-if="!this.currentFlow.isRunning"
                  flat dense
                  class="app-action-btn q-pa-sm q-mr-sm"
                  :label="$t('flow.overview.detail.start')"
                  :disable="isLoading"
                  @click="!this.currentFlow.isRunning ? startFlow() : null"
                  v-bind:data-cy="'buttonQuickStartFlow' + this.currentFlow.id"
                ><q-icon name="arrow_right_alt" class="q-ml-sm" />
                </q-btn>

                <q-btn v-if="this.currentFlow.isRunning"
                  flat dense
                  class="app-action-btn q-pa-sm q-mr-sm"
                  :label="$t('flow.overview.detail.end')"
                  :disable="isLoading"
                  @click="showEndFlowModal = true"
                  v-bind:data-cy="'buttonQuickEndFlow' + this.currentFlow.id"
                ><q-icon name="do_disturb" class="q-ml-sm" />
                </q-btn>

                <q-btn flat dense rounded
                  class="app-action-btn app-export-flow-btn q-pa-sm q-mr-sm"
                  icon-right="file_upload"
                  :label="$t('flow.overview.detail.export')"
                  :title="$t('flow.overview.detail.export')"
                  @click.capture.stop="showExportFlowModal = true"
                />

                <q-btn
                  flat dense
                  class="app-action-btn app-edit-flowname q-pa-sm q-mr-sm"
                  :label="$t('flow.overview.detail.renameFlow')"
                  :title="$t('flow.overview.detail.renameFlow')"
                  @click="showFlowModal = true"
                  ><q-icon name="img:assets/icons/edit.svg" class="q-ml-sm" />
                </q-btn>
              </div>

            <div class="row justify-between items-center full-width q-mt-md q-mt-md-sm last-execution-info" v-if="currentFlow.lastFlowExecutionId">
              <flow-item-details :flow="currentFlow"  :flow-blocked="flowBlocked" showElapsedTime></flow-item-details>
            </div>
          </div>
        </div>
      </div>


      <loading-indicator v-if="currentFlow === null" />
      <template v-if="currentFlow !== null">

        <div>
          <q-card class="app-expansion-section">
            <q-expansion-item
              class="q-mb-md q-mx-md"
              v-model="expandConf"
              switch-toggle-side
              default-opened
              data-cy="sectionConfiguration"
            >

              <template v-slot:header>
                <q-item-section class="one-liner">
                  <div class="one-liner-child">{{ $t('flow.overview.detail.configuration.title') }}</div>
                </q-item-section>
              </template>
              <q-card class="bg-transparent">
                <q-card-section>
                  <div class="relative-position flex justify-center items-center q-px-lg q-py-md">
                    <json-editor
                      v-model="configurationModel"
                      contrast
                      wrapped
                      :key="'flowConfig' + configurationKey"
                      max-height="100%"
                      :disabled="isLoading"
                      allow-empty
                      @is-valid-json="checkValidJson"
                    />
                    <div class="flex full-width q-mt-md justify-end">
                      <q-btn flat dense
                             icon-right="update"
                             :label="$t('flow.overview.detail.configuration.button')"
                             :title="$t('flow.overview.detail.configuration.button')"
                             class="app-action-btn q-pa-sm"
                             @click.capture.stop='updateFlowConfig()'
                             :disabled="isLoading || configurationEmpty || !configurationChanged || !configurationValid"
                      />
                    </div>
                  </div>
                </q-card-section>
              </q-card>
            </q-expansion-item>
          </q-card>


          <q-tabs v-model="currentTab" align="left" dense active-color="primary" indicator-color="primary" narrow-indicator>
            <q-tab name="jdms" :label="$t('flow.overview.detail.tab.jdms')" :ripple="false" data-cy="jdmTab" />
            <q-tab name="executions" :label="$t('flow.overview.detail.tab.executions')" :ripple="false" data-cy="executionTab" />
          </q-tabs>
          <q-tab-panels v-model="currentTab" animated>
            <q-tab-panel name="jdms">
              <q-card class="app-expansion-section">
                <q-expansion-item
                  class="q-mb-md q-mx-md"
                  v-model="expandInput"
                  switch-toggle-side
                  default-opened
                  data-cy="sectionInput"
                >

                  <template v-slot:header>
                    <q-item-section>
                      <div class="row no-wrap one-liner">
                        <div class="one-liner-child">{{ $t('flow.overview.detail.input.title') }}</div>
                        <q-badge class="q-ml-sm q-pb-xs self-center items-center">{{ getInputMappings.length }}</q-badge>
                      </div>
                    </q-item-section>
                    <q-item-section side>
                      <q-btn
                        flat dense icon-right="add"
                        class="q-px-sm justify-end app-action-btn"
                        :label="$t('flow.overview.detail.addJdmShort')"
                        :title="$t('flow.overview.detail.addJdm')"
                        @click.capture.stop="createJdmModal('input')"
                        :disabled="isLoading"
                        data-cy="buttonAddInputJdm"
                      />
                    </q-item-section>
                  </template>
                  <q-card class="bg-transparent">
                    <q-card-section>
                      <div class="app-jdm-section">
                        <jdm-item
                          v-if="!(!isLoading && getInputMappings.length <= 0)"
                          v-for="mapping in getInputMappings"
                          :jdm="mapping"
                          :key="mapping.id"
                          :flow="currentFlow"
                          @reload="pageLoad($event)"
                        />
                        <div v-if="(!isLoading && getInputMappings.length <= 0)" class="app-jdm-empty">
                          <p class="q-my-sm text-light">{{ $t('flow.overview.detail.input.empty') }}</p>
                        </div>
                      </div>
                    </q-card-section>
                  </q-card>
                </q-expansion-item>
              </q-card>

              <q-card class="app-expansion-section">
                <q-expansion-item
                  class="q-mb-md q-mx-md"
                  v-model="expandTransform"
                  switch-toggle-side
                  default-opened
                  data-cy="sectionTransformation"
                >

                  <template v-slot:header>
                    <q-item-section>
                      <div class="row no-wrap one-liner">
                        <div class="one-liner-child">{{ $t('flow.overview.detail.transformation.title') }}</div>
                        <q-badge class="q-ml-sm q-pb-xs self-center items-center">{{ getTransformationMappings.length }}</q-badge>
                      </div>
                    </q-item-section>
                    <q-item-section side>
                      <q-btn
                        flat dense icon-right="add"
                        class="q-px-sm justify-end app-action-btn"
                        :label="$t('flow.overview.detail.addJdmShort')"
                        :title="$t('flow.overview.detail.addJdm')"
                        @click.capture.stop="createJdmModal('transformations')"
                        :disabled="isLoading"
                        data-cy="buttonAddTransformationJdm"
                      />
                    </q-item-section>
                  </template>
                  <q-card class="bg-transparent">
                    <q-card-section>
                      <div class="app-jdm-section">
                        <jdm-item v-if="!(!isLoading && getTransformationMappings.length <= 0)"
                                  v-for="mapping in getTransformationMappings"
                                  :jdm="mapping"
                                  :key="mapping.id"
                                  :flow="currentFlow"
                                  @reload="pageLoad($event)"
                        />
                        <div v-if="(!isLoading && getTransformationMappings.length <= 0)" class="app-jdm-empty">
                          <p class="q-my-sm text-light">{{ $t('flow.overview.detail.transformation.empty') }}</p>
                        </div>
                      </div>
                    </q-card-section>
                  </q-card>
                </q-expansion-item>
              </q-card>

              <q-card class="app-expansion-section">
                <q-expansion-item
                  class="q-mb-md q-mx-md"
                  v-model="expandOutput"
                  switch-toggle-side
                  default-opened
                  data-cy="sectionOutput"
                >

                  <template v-slot:header>
                    <q-item-section>
                      <div class="row no-wrap one-liner">
                        <div class="one-liner-child">{{$t('flow.overview.detail.output.title')}}</div>
                        <q-badge class="q-ml-sm q-pb-xs self-center items-center" color="primary">{{ getOutputMappings.length }}</q-badge>
                      </div>
                    </q-item-section>
                    <q-item-section side>
                      <q-btn
                        flat dense icon-right="add"
                        class="q-px-sm justify-end app-action-btn"
                        :label="$t('flow.overview.detail.addJdmShort')"
                        :title="$t('flow.overview.detail.addJdm')"
                        @click.capture.stop="createJdmModal('output')"
                        :disabled="isLoading"
                        data-cy="buttonAddOutputJdm"
                      />
                    </q-item-section>
                  </template>
                  <q-card class="bg-transparent">
                    <q-card-section>
                      <div class="app-jdm-section">
                        <jdm-item
                          v-if="!(!isLoading && getOutputMappings.length <= 0)"
                          v-for="mapping in getOutputMappings"
                          :jdm="mapping"
                          :key="mapping.id"
                          :flow="currentFlow"
                          @reload="pageLoad($event)"
                        />
                        <div v-if="(!isLoading && getOutputMappings.length <= 0)" class="app-jdm-empty">
                          <p class="q-my-sm text-light">{{ $t('flow.overview.detail.output.empty') }}</p>
                        </div>
                      </div>
                    </q-card-section>
                  </q-card>
                </q-expansion-item>
              </q-card>
            </q-tab-panel>
            <q-tab-panel name="executions">
              <q-table
                v-if="!isLoading && items.length > 0"
                :rows="items"
                :columns="this.columns"
                color="primary"
                hide-bottom
                hide-pagination
                :rows-per-page-options="[pagination.itemsPerPage]"
                flat
                class="app-flow-execution-table col-12 transparent"
              >
                <template v-slot:body="props">
                  <q-tr
                    :props="props"
                    class="cursor-pointer"
                    @click.exact="goToDetail(null, props.row)"
                    @click.middle.exact="goToDetail(null, props.row, true)"
                    @click.ctrl.exact="goToDetail(null, props.row, true)"
                  >
                    <q-td
                      v-for="col in props.cols"
                      :key="col.name"
                      :props="props"
                    >
                      <template v-if="col.name === 'success'">
                        <status-badge
                          :running="Boolean(props.row.active)"
                          :status="props.row.success"
                          :running-tooltip="$t('flow.status.runningTooltip')"
                          :positive-tooltip="$t('flow.status.positiveTooltip')"
                          :negative-tooltip="$t('flow.status.negativeTooltip')"
                        />
                      </template>
                      <template v-else-if="col.name === 'duration'">
                        <date-display :start-time="props.row.created_at" :end-time="props.row.updated_at" />
                      </template>
                      <template v-else-if="col.name === 'created'">
                        <date-display :start-time="props.row.created_at" only-date />
                      </template>
                      <template v-else-if="col.name === 'time'">
                        <date-display :start-time="props.row.updated_at" only-date />
                      </template>
                      <template v-else-if="col.name === 'link'">
                        <q-btn
                          type="a"
                          flat dense
                          :ripple="!isLoading"
                          class="app-jump-to-execution"
                          icon="img:assets/icons/link.svg"
                          :title="$t('flow.executions.gotoDetailNewTab')"
                          :disable="isLoading"
                          @click="$event.stopPropagation(); goToDetail(null, props.row, true)"
                        />
                      </template>
                      <template v-else>
                        <template v-if="col.name === 'id'">#</template>
                        {{ col.value }}
                      </template>
                    </q-td>
                  </q-tr>
                </template>
              </q-table>

              <paginator
                v-model:page="pagination.page"
                v-model:itemsPerPage="pagination.itemsPerPage"
                :total-pages="pagination.pageCount"
                class="q-mt-lg"
                @update:page="pageLoad"
                @update:items-per-page="resetPageAndReload"
              />
            </q-tab-panel>
          </q-tab-panels>

          <export-flow-modal
            v-model="showExportFlowModal"
            :flow="$route.params.id"
          />

          <flow-modal
            v-model="showFlowModal"
            :flow="currentFlow"
            @updated="handleUpdated"
          />

          <end-flow-modal
            v-model="showEndFlowModal"
            page="flow"
            :flow-id="currentFlow?.lastFlowExecutionId"
            :flow-name="currentFlow?.name"
            @success="handleFlowEnded"
          />

          <create-job-dispatcher-mapping-modal
            v-model="showJdmModal"
            :flow="currentFlow"
            :process-step="processStep"
            @created="handleJdmCreated"
          />

        </div>
      </template>
    </div>
  </q-page>
</template>

<style lang="scss">
.q-page-container .q-page .app-dashcard-container .app-dashcard.app-flow-execution-card {
  margin-left: unset;
}
.app-expansion-section {
  margin: 0 1rem 1.5rem;
  @media (min-width: $breakpoint-lg) {
    margin: 0 0 1.5rem;
  }
  .q-expansion-item {
    margin: unset;
    &.q-expansion-item--expanded {
      padding-bottom: .5rem;
    }
    .q-item {
      transition: $transition;
    }
    &.q-expansion-item--collapsed .q-item {
      border-radius: $br;
    }
    &.q-expansion-item--expanded .q-item {
      border-top-left-radius: $br;
      border-top-right-radius: $br;
    }
  }
  .one-liner {
    max-width: 100%;
    &-child {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 600;
    }
  }
}

.app-flow-detail {
  .app-jdm-item + .app-jdm-item {
    margin-top: 1rem;
  }
}

.app-flow-execution-card {
  padding: unset;
}

.app-flow-detail .controls .app-action-btn {
  margin-top: 1rem;
  @media (min-width: $breakpoint-sm) {
    margin-top: unset;
  }
}

.app-jdm-section {
  padding: 0 .8rem;
  .app-jdm-empty {
    padding: 0 .5rem;
  }
}

body.body--dark {
  .app-jump-to-execution {
    color: $light;
    .q-icon {
      filter: invert(1);
    }
  }
}
@media (max-width: $breakpoint-xs) {
  .last-execution-info {
    justify-content: flex-start;

    &>div { margin-right: 1rem; }
  }
}

.app-flow-detail .app-headline-container .app-edit-flowname {
  img {
    width: 1rem;
    height: 1rem;
    filter: invert(1);
  }
}
</style>
