export function initialPageHelper(query) {
  const defaultValue = 1;
  const initialPage = parseInt(query?.p) || defaultValue;

  if(!isNaN(initialPage)) {
    // Update model with our GET value
    return initialPage;
  } else {
    // default because of erroneous input
    return defaultValue;
  }
}

export function itemsPerPageHelper(query) {
  const user = JSON.parse(localStorage.getItem('user'));
  const preferredItemsPerPage = parseInt(user.user_preference.itemsPerPage);
  const defaultValue = preferredItemsPerPage ?? 10;
  const itemsPerPage = parseInt(query?.i) || defaultValue;

  if(!isNaN(itemsPerPage)) {
    // Update model with our GET value
    return itemsPerPage;
  } else {
    // default because of erroneous input
    return defaultValue;
  }
}

export function watchPagination(app, funct) {
  const source = ['requestedPage', 'requestedItemsPerPage'];
  source.forEach(function(val) {
    app.$watch(val, funct.bind(app));
  })
}
