<script>
import {debounce, useQuasar} from 'quasar'
  import { defineComponent, ref } from 'vue'
  import { mapActions, mapState } from 'vuex'
import {authService, preferenceService} from 'src/services'
  import * as PREFERENCES from 'src/meta/preferences'
  import { updatePrefInLocalStorage, setDarkMode } from 'src/utils'

  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import PreferenceItem from 'pages/Preferences/Components/PreferenceItem.vue'
  import LocalePicker from 'pages/Preferences/Components/LocalePicker.vue'
  import ThemePicker from 'pages/Preferences/Components/ThemePicker.vue'
  import UserModal from "pages/UserManagement/components/UserModal.vue";

  export default defineComponent({
    name: 'Preferences',

    components: {
      UserModal,
      ThemePicker,
      LocalePicker,
      PreferenceItem,
      LoadingIndicator
    },
    setup () {
      const $q = useQuasar();
    },
    data () {
      return {
        PREFERENCES,
        cardLoaders: {
          enableEmailNotification: false,
          locale: false,
          theme: false,
          flowFailedEmailNotification: false,
          messageFailedEmailNotification: false,
          diskFullEmailNotification: false,
          heartbeatFailedEmailNotification: false,
        },
        avatarModel: ref(null),
        preferenceForm: {
          theme: null,
          locale: null,
          enableEmailNotification: false,
          flowFailedEmailNotification: false,
          messageFailedEmailNotification: false,
          diskFullEmailNotification: false,
          heartbeatFailedEmailNotification: false,
          heartbeatUnavailableInSeconds: "120",
          heartbeatMailInterval: "600",
          itemsPerPage: null
        },
        hbInit: false, // Need this to double-check heartbeat init flag, because it isn't updated right at the heartbeatChildsInitialized computed property,
        showUserModal: false,
        user: null,
        oldValue: null,
        minimumInterval: 20,
        minimumItemsPerPage: 2,
        maximumItemsPerPage: 100
      }
    },

    computed: {
      ...mapState('preferences', ['userPreferences']),
      heartbeatChildsInitialized() {
        let u = JSON.parse(localStorage.getItem("user"));
        return !!(u.user_preference.heartbeatUnavailableInSeconds && u.user_preference.heartbeatMailInterval);
      }
    },

    methods: {
      ...mapActions('preferences', ['updatePrefInState']),

      updatePreference: async function (name, value) {
        if(name === this.PREFERENCES.PREF_LOCALE) this.$q.lang.isoName = value;
        this.$store.dispatch('preferences/startUpdate');

        const payload = [{
          "preferenceKey": name,
          "preference": value
        }]

        try {
          this.cardLoaders[name] = true

          await preferenceService.updatePreference(payload)

          await this.updatePrefInState({
            key: name,
            value: this.reCastValue(value)
          })

          updatePrefInLocalStorage(name, value)

          this.$q.notify({
            message: this.$t('preferences.preferenceUpdateSuccess'),
            color: 'positive'
          })

          this.afterEffect(name, value)
        } catch (error) {
          console.error(error);
          this.$q.notify({
            message: this.$t('preferences.preferenceUpdateFailed'),
            color: 'negative'
          })
        } finally {
          this.cardLoaders[name] = false
        }
      },

      debouncedUpdatePreference: debounce(function(name, value) {
        this.updatePreference(name, value);
      }, 200),


      updateMultiplePreferences(array) { // NOTE: Optimization method. Send all child prefs to prevent nullables in the api
        array.forEach((entry) => {
          this.updatePreference(entry.key, entry.value);
        });
        this.hbInit = true;
      },

      reCastValue(value) {
        if (typeof value === 'number') {
          return Number(value)
        }
        return value
      },

      afterEffect(name, value) {
        if (name === 'theme') setDarkMode(value)
      },

      itemsPerPageUpdate(event) {

        const value = event.target.value;

        let sanitizedValue = Math.abs(parseInt(value, 10));

        if (sanitizedValue === 0 || isNaN(sanitizedValue) || sanitizedValue === null) sanitizedValue = this.userPreferences.itemsPerPage; // Reset if faulty
        else if (sanitizedValue > this.maximumItemsPerPage) sanitizedValue = this.maximumItemsPerPage; // Upper cap
        else if (sanitizedValue === this.minimumItemsPerPage) sanitizedValue = this.minimumItemsPerPage; // Lower cap

        this.preferenceForm.itemsPerPage = sanitizedValue;

        if(sanitizedValue !== this.oldValue) {
          this.oldValue = sanitizedValue;
          this.debouncedUpdatePreference(PREFERENCES.PREF_ITEMS_PER_PAGE, sanitizedValue);
        }
      },

      handleShowUserModal() {
        authService.getUser((data) => {
          if(data.success === 1) {
            this.user = JSON.parse(data.data);
            this.showUserModal = true;
          } else {
            this.$q.notify({
              message: this.$t('preferences.userFetchFailed'),
              color: 'negative'
            })
          }
        });
      },

      handleUserUpdated() {
        this.showUserModal = false;
      },

      handleHeartbeatInputUpdate(event, apiKey) {
        const formKey = apiKey === this.PREFERENCES.PREF_HEARTBEAT_UNAVAILABLE_IN_SECONDS ? 'heartbeatUnavailableInSeconds' : apiKey === this.PREFERENCES.PREF_HEARTBEAT_MAIL_INTERVAL ? 'heartbeatMailInterval' : null;

        // First do a check to prevent negative inputs
        let val = Math.abs(parseInt(event.target.value, 10));
        if(val === 0 || isNaN(val)) val = this.userPreferences[formKey] // Can't be 0. Reset to previous value
        if(val < this.minimumInterval) val = this.minimumInterval;

        this.preferenceForm[formKey] = val;
        // Ignore if value remained the same after blur
        if(this.preferenceForm[formKey] === this.userPreferences[formKey]) return;

        // Update the preference if the value changed
        this.debouncedUpdatePreference(apiKey, val)
      }
    },

    mounted () {
      this.preferenceForm.theme = this.userPreferences.theme || 'auto'
      this.$i18n.locale = this.$q.lang.isoName = this.userPreferences.locale || 'en-US'
      this.preferenceForm.enableEmailNotification = !!Number(this.userPreferences.enableEmailNotification)
      this.preferenceForm.flowFailedEmailNotification = !!Number(this.userPreferences.flowFailedEmailNotification)
      this.preferenceForm.messageFailedEmailNotification = !!Number(this.userPreferences.messageFailedEmailNotification)
      this.preferenceForm.diskFullEmailNotification = !!Number(this.userPreferences.diskFullEmailNotification)
      this.preferenceForm.heartbeatFailedEmailNotification = !!Number(this.userPreferences.heartbeatFailedEmailNotification)
      this.preferenceForm.heartbeatUnavailableInSeconds = this.userPreferences.heartbeatUnavailableInSeconds || this.preferenceForm.heartbeatUnavailableInSeconds
      this.preferenceForm.heartbeatMailInterval = this.userPreferences.heartbeatMailInterval || this.preferenceForm.heartbeatMailInterval
      this.preferenceForm.itemsPerPage = this.userPreferences.itemsPerPage
      this.oldValue = this.userPreferences.itemsPerPage;
    }
  })
</script>

<template>
  <q-page class="block relative-position">
    <div class="q-pa-md">
      <h1 class="q-mb-lg">{{ $t('preferences.headline') }}</h1>

      <div class="app-preferences row">
        <preference-item
          :label="$t('preferences.user')"
          :loading="cardLoaders.user"
          class="col-12 col-sm-6 col-lg-2"
        >
          <span>
            <q-btn class="app-action-btn sq-edit-own-user-btn"
                   dense unelevated
                   :label="$t('general.edit')"
                   disable
            />
          <q-tooltip anchor="top middle" self="bottom middle">Feature will come with next update</q-tooltip>
          </span>
          <!-- @click.capture.stop="handleShowUserModal" -->
            <!--
          <user-modal
            v-model="showUserModal"
            :user="user"
            @saved="handleUserUpdated"
          />-->
        </preference-item>

        <preference-item
          :label="$t('preferences.languageSelect')"
          :loading="cardLoaders.locale"
          class="col-12 col-sm-6 col-lg-4"
        >
          <locale-picker
            v-model="$i18n.locale"
            @update:model-value="(value) => debouncedUpdatePreference(PREFERENCES.PREF_LOCALE, value)"
            data-cy="selectLangPref"
          />
        </preference-item>

        <preference-item
          :label="$t('preferences.themeSelect')"
          :loading="cardLoaders.theme"
          class="col-12 col-sm-6 col-lg-4"
        >
          <theme-picker
            v-model="preferenceForm.theme"
            @update:model-value="(value) => debouncedUpdatePreference(PREFERENCES.PREF_THEME, value)"
            data-cy="selectThemePref"
          />
        </preference-item>

        <preference-item
          :label="$t('preferences.itemsPerPage')"
          :loading="cardLoaders.itemsPerPage"
          class="col-12 col-sm-6 col-lg-2"
        >
          <q-input dense
                   outlined
                   v-model.number="preferenceForm.itemsPerPage"
                   @blur="(event) => itemsPerPageUpdate(event)"
                   type="number"
                   :placeholder="$t('preferences.itemsPerPagePlaceholder', { min: minimumItemsPerPage, max: maximumItemsPerPage })"
                   :min="minimumItemsPerPage"
                   :max="maximumItemsPerPage"
                   class="sq-items-per-page-input"
          />
        </preference-item>
      </div>

      <div class="app-preferences row">
        <preference-item :loading="cardLoaders.enableEmailNotification">
          <template #header>
            <q-toggle
              v-model="preferenceForm.enableEmailNotification"
              :label="$t('preferences.enableEmailNotification')"
              @update:model-value="(value) => debouncedUpdatePreference(PREFERENCES.PREF_ENABLE_EMAIL_NOTIFICATION, value)"
              data-cy="setNotifyOnErrorPref"
            />
          </template>

          <q-slide-transition>
            <div
              v-show="preferenceForm.enableEmailNotification"
              class="q-ma-sm q-mb-md"
            >
              <q-card
                bordered
                flat
                class="q-mb-md"
              >
                <q-card-section>
                  <q-checkbox
                    v-model="preferenceForm.flowFailedEmailNotification"
                    :disable="cardLoaders.flowFailedEmailNotification"
                    class="full-width"
                    @update:model-value="(value) => debouncedUpdatePreference(PREFERENCES.PREF_FLOW_FAILED_EMAIL_NOTIFICATION, value)"
                    data-cy="setFlowFailedPref"
                  >
                    {{ $t('preferences.emailNotifications.flowFailed') }}
                  </q-checkbox>
                </q-card-section>

                <q-inner-loading
                  :showing="cardLoaders.flowFailedEmailNotification"
                  color="primary"
                  size="2em"
                />
              </q-card>

              <q-card
                bordered
                flat
                class="q-my-md"
              >
                <q-card-section>
                  <q-checkbox
                    v-model="preferenceForm.messageFailedEmailNotification"
                    :disable="cardLoaders.messageFailedEmailNotification"
                    class="full-width"
                    @update:model-value="(value) => debouncedUpdatePreference(PREFERENCES.PREF_MESSAGE_FAILED_EMAIL_NOTIFICATION, value)"
                    data-cy="setMessageFailedPref"
                  >
                    {{ $t('preferences.emailNotifications.messageFailed') }}
                  </q-checkbox>
                </q-card-section>

                <q-inner-loading
                  :showing="cardLoaders.messageFailedEmailNotification"
                  color="primary"
                  size="2em"
                />
              </q-card>

              <q-card
                bordered
                flat
                class="q-my-md"
              >
                <q-card-section>
                  <q-checkbox
                    v-model="preferenceForm.diskFullEmailNotification"
                    :disable="cardLoaders.diskFullEmailNotification"
                    class="full-width"
                    @update:model-value="(value) => debouncedUpdatePreference(PREFERENCES.PREF_DISK_FULL_EMAIL_NOTIFICATION, value)"
                    data-cy="setDiskFullPref"
                  >
                    {{ $t('preferences.emailNotifications.diskFull') }}
                  </q-checkbox>
                </q-card-section>

                <q-inner-loading
                  :showing="cardLoaders.diskFullEmailNotification"
                  color="primary"
                  size="2em"
                />
              </q-card>

              <q-card bordered flat class="q-my-md">
                <q-card-section>
                  <q-checkbox
                    v-model="preferenceForm.heartbeatFailedEmailNotification"
                    :disable="cardLoaders.heartbeatFailedEmailNotification"
                    class="full-width"
                    @update:model-value="(value) => !heartbeatChildsInitialized && !hbInit ? updateMultiplePreferences([
                      { key: PREFERENCES.PREF_HEARTBEAT_FAILED_EMAIL_NOTIFICATION, value },
                      { key: PREFERENCES.PREF_HEARTBEAT_UNAVAILABLE_IN_SECONDS, value: preferenceForm.heartbeatUnavailableInSeconds },
                      { key: PREFERENCES.PREF_HEARTBEAT_MAIL_INTERVAL, value: preferenceForm.heartbeatMailInterval }
                      ]) : debouncedUpdatePreference(PREFERENCES.PREF_HEARTBEAT_FAILED_EMAIL_NOTIFICATION, value)"
                    data-cy="setHeartbeatFailedPref"
                  >
                    {{ $t('preferences.emailNotifications.heartbeatFailed') }}
                  </q-checkbox>

                  <q-input required
                           outlined
                           v-show="preferenceForm.heartbeatFailedEmailNotification"
                           class="q-pa-sm"
                           v-model="preferenceForm.heartbeatUnavailableInSeconds"
                           @blur="(event) => handleHeartbeatInputUpdate(event, PREFERENCES.PREF_HEARTBEAT_UNAVAILABLE_IN_SECONDS)"
                           aria-autocomplete="none"
                           :label="$t('preferences.heartbeatUnavailableInSeconds', { minimumInterval })"
                           type="number"
                           :min="10"
                  />

                  <q-input required
                           outlined
                           v-show="preferenceForm.heartbeatFailedEmailNotification"
                           class="q-pa-sm"
                           v-model="preferenceForm.heartbeatMailInterval"
                           @blur="(event) => handleHeartbeatInputUpdate(event, PREFERENCES.PREF_HEARTBEAT_MAIL_INTERVAL)"
                           aria-autocomplete="none"
                           :label="$t('preferences.heartbeatMailInterval', { minimumInterval })"
                           type="number"
                           :min="minimumInterval"
                  />
                </q-card-section>
                <q-inner-loading :showing="cardLoaders.heartbeatFailedEmailNotification" color="primary" size="2em" />
              </q-card>

            </div>
          </q-slide-transition>
        </preference-item>

      </div>

    </div>
  </q-page>
</template>

<style lang="scss">
  .sq-edit-own-user-btn {
    width: calc(100% - 1.5rem);
    margin: 0 0 0 .5rem;
    padding: .25rem .75rem;
  }
  .sq-items-per-page-input {
    width: calc(100% - .5rem);
  }
</style>
