<script>
import { defineComponent, ref } from 'vue'
import { mapState, useStore } from 'vuex'
import { flowService, modulesService } from 'src/services';

import SqDialog from 'components/Common/SqDialog.vue'
import { notifyError } from 'src/utils/notify'

export default defineComponent({
  name: 'CreateJobDispatcherMappingModal',

  components: {
    SqDialog
  },

  emits: ['created'],

  props: {
    flow: {
      type: Object
    },

    processStep: {},
    processStepSelect: {}
  },

  setup (props, { emit }) {
    const $store = useStore()

    const selectedFqcn = ref(null)
    const configSnippets = ref([])
    const snippetId = ref(null)
    const selectedProcessStep = ref(null)
    const isPostingJdm = ref(false)
    const optionFilter = ref('')
    const chosenProcessStep = ref(props.processStep)
    const processStepFilter = ref('')
    const modules = ref([])
    const isLoading = ref(false)
    const active = true

    function addJdm() {
      isPostingJdm.value = true

      let jdmObj = {
        dispatchCondition: [],
        configuration: {},
        messageFqcn: selectedFqcn.value,
        processStepIdentifier: props.processStep ? props.processStep : chosenProcessStep.value,
        flowId: props.flow['@id'] ? props.flow['@id'] : "/api/flows/" + props.flow.toString(),
        template: snippetId.value?.id ? `/api/config-snippets/${snippetId.value.id}` : null,
        active
      };

      this.selectedFqcn = ref(null); // Reset for next jdm
      flowService.createJobDispatcherMapping(jdmObj, (data) => {
        isPostingJdm.value = false
        if (typeof data?.data !== "undefined" && data?.data['@type'] === "hydra:Error") {
          $store.dispatch('alert/error', 'flow.overview.detail.modal.jdmCreationFailed');
        } else {
          $store.dispatch('alert/success', 'flow.overview.detail.modal.jdmCreationSuccess');
          if (typeof props.processStepSelect === "undefined") { // Only update jdm view if we are on detail page
            let found = false;
            props.flow.jobDispatcherMappings.forEach((elem, index) => {
              if (jdmObj && elem['@id'] === jdmObj['@id']) {
                props.flow.jobDispatcherMappings[index] = jdmObj;
                found = true;
              }
            })

            if (!found) props.flow.jobDispatcherMappings.push(jdmObj);
          }

          emit('created', data)
        }
      })
    }

    return {
      modules,
      isLoading,
      selectedFqcn,
      configSnippets,
      snippetId,
      isPostingJdm,
      optionFilter,
      chosenProcessStep,
      processStepFilter,
      selectedProcessStep,

      addJdm
    }
  },

  computed: {
    ...mapState('modules', ['processSteps']),

    processStepOptions: function () {
      return this.processSteps.filter(v => v.toLowerCase().indexOf(this.processStepFilter.toLowerCase()) > -1);
    },

    options: function () {
      return this.modules.filter(v => v.toLowerCase().indexOf(this.optionFilter.toLowerCase()) > -1);
    }
  },

  methods: {
    filterFn (val, update) {
      update(() => {
        this.optionFilter = val;
      })
    },

    async getModules() {
      modulesService.getModules((data) => {
        try {
          this.modules = data['hydra:member'];
          if(this.modules.length > 0) this.$refs.selectFqcnRef.showPopup();
        } catch(e) {
          this.$store.dispatch('alert/error', e, { root: true });
        } finally {
          this.isLoading = false;
        }
      })
    },

    async getConfigSnippets() {
      try {
        const response = await this.$api.configSnippet.list({ template: true })

        if (response.data) {
          this.configSnippets = response.data.list

          return
        }

        notifyError('Something went wrong while loading config snippets. Please refresh and try again.')
      } catch (error) {
        console.error(error)

        notifyError('Something went wrong while loading config snippets. Please refresh and try again.')
      }
    },

    async initialize() {
      this.isLoading = true;
      await this.getConfigSnippets()
      await this.getModules()
    },

    resetState() {
      this.snippetId = null
    }
  }
})
</script>

<template>
  <sq-dialog
    type="create"
    size="md"
    :loading="isLoading || isPostingJdm"
    @save="addJdm"
    @show="initialize"
    @hide="resetState"
  >
    <template #title>
      {{ $t('flow.overview.detail.modal.addJdm') }}
    </template>

    <template #content>
      <div class="q-my-md">
        <q-select
          v-if="!processStep || processStepSelect"
          v-model="chosenProcessStep"
          dense outlined
          use-input
          hide-selected
          fill-input
          :disable="isPostingJdm"
          :options="processStepOptions"
          :label="$t('flow.overview.detail.modal.chooseProcessStep')"
          input-debounce="0"
          data-cy="selectProcessStep"
          class="app-select-process-step-input q-mb-md"
          @filter="filterFn"
        />

        <q-select
          v-model="selectedFqcn"
          ref="selectFqcnRef"
          dense outlined
          use-input
          hide-selected
          fill-input
          :disable="isPostingJdm"
          :options="options"
          :label="$t('flow.overview.detail.modal.chooseFQCN')"
          input-debounce="0"
          data-cy="selectFqcn"
          class="app-select-fqcn-input"
          @filter="filterFn"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                {{ $t('flow.overview.detail.modal.noFQCN') }}
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <q-select
          v-model="snippetId"
          ref="snippetSelectorRef"
          dense outlined
          use-input
          hide-selected
          fill-input
          option-label="key"
          option-value="id"
          :disable="isPostingJdm"
          :options="configSnippets"
          label="Select Snippet as Template"
          input-debounce="0"
          class="app-select-fqcn-input q-mt-md"
          @filter="filterFn"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                {{ $t('flow.overview.detail.modal.noFQCN') }}
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
    </template>

    <template #actions>
      <q-btn
        flat
        v-close-popup
        :label="$t('general.cancel')"
      />
      <q-btn
        flat
        :disabled="!this.selectedFqcn || (processStep === null && selectedProcessStep === null ) || isPostingJdm"
        :label="$t('general.save')"
        color="primary"
        data-cy="buttonSave"
        @click="addJdm()"
      />
    </template>
  </sq-dialog>
</template>

<style lang="scss">
  .app-select-fqcn-input {
    border: unset;
    border-bottom: $layout-border;
    background-color: $background2;
    &:focus-visible {
      outline: unset;
    }
  }
  .app-select-fqcn-input, .app-select-process-step-input {
    .q-field__inner .q-field__control {
      padding: 0 .5rem;
    }
  }
  body.body--dark {
    .app-select-fqcn-input {
      background-color: $dark-page;
    }
  }
</style>
