<script>
  import { CONFIG_SNIPPET_COLUMNS } from 'pages/ConfigSnippets/template'
  import { DEFAULT_PAGINATION, translateColumns } from 'src/utils'
  import { notifyError, notifySuccess } from 'src/utils/notify'
  import ConfigSnippetFormDialog from 'pages/ConfigSnippets/ConfigSnippetFormDialog.vue'
  import ConfirmationDialog from 'components/Functional/ConfirmationDialog.vue'
  import ConfigSnippetPreviewDialog from 'pages/ConfigSnippets/ConfigSnippetPreviewDialog.vue'
  import JsonEditor from "components/JsonEditor.vue";

  export default {
    name: 'ConfigSnippetContent',

    emits: ['update'],

    props: {
      type: {
        type: Number,
        required: true
      },
      content: {
        type: Array,
        required: true
      }
    },

    components: {
      JsonEditor,
      ConfigSnippetPreviewDialog,
      ConfirmationDialog,
      ConfigSnippetFormDialog
    },

    data() {
      return {
        DEFAULT_PAGINATION,

        showSnippetFormDialog: false,
        showDeleteConfirmation: false,
        showPreviewDialog: false,
        selectedConfigSnippet: null,
        currentTab: 'config',
        searchTerm: ""
      }
    },

    computed: {
      columns() {
        return translateColumns(this.$t, CONFIG_SNIPPET_COLUMNS);
      },
      filteredContent() {
        return this.$props.content
          .filter(item => item.type === this.$props.type)  // Original filter to split between types
          .filter(item => { // Search filter
            if (!this.searchTerm) return true;
            return item.key.toLowerCase().includes(this.searchTerm.toLowerCase());
          });
      }

    },

    methods: {

      handleSnippetCreated(configSnippet) {
        this.$props.content.push(configSnippet)
        this.showSnippetFormDialog = false;
      },

      handleSnippetUpdated(configSnippet) {
        this.replaceFromList(configSnippet.id, configSnippet)
        this.showSnippetFormDialog = false
        this.selectedConfigSnippet = null
      },

      handleEditSnippet(configSnippet) {
        this.selectedConfigSnippet = { ...configSnippet }
        this.showSnippetFormDialog = true;
      },

      handlePreviewSnippet(configSnippet) {
        this.selectedConfigSnippet = { ...configSnippet }
        this.showPreviewDialog = true
      },

      handlePreviewClose() {
        this.selectedConfigSnippet = null
      },

      handleDeleteSnippet(configSnippet) {
        this.selectedConfigSnippet = { ...configSnippet }
        this.showDeleteConfirmation = true
      },

      closeDeleteSnippetDialog() {
        this.selectedConfigSnippet = null
        this.showDeleteConfirmation = false;
      },

      async handleConfirmDeleteConfigSnippet() {
        try {
          this.loading = true
          const id = this.selectedConfigSnippet.id
          const response = await this.$api.configSnippet.remove(id)
          if (response.data) {
            this.removeFromList(id)
            notifySuccess(this.$t('configSnippets.deleteSuccess'))
            this.showDeleteConfirmation = false
            return
          }

          notifyError(this.$t('configSnippets.deleteFail'))
        } catch (error) {
          console.error(error)
          const status = error.response?.status
          if (status === 404) {
            return notifyError(this.$t('configSnippets.deleteFail'))
          }
          notifyError(this.$t('configSnippets.deleteFail'))
        } finally {
          this.loading = false
        }
      },

      handleFormDialogClose() {
        this.showSnippetFormDialog = false
        this.selectedConfigSnippet = null
      },

      removeFromList(id) {
        const index = this.content.findIndex((cs) => Number(cs.id) === Number(id))
        if (index > -1) this.content.splice(index, 1)
      },

      replaceFromList(id, payload) {
        const index = this.content.findIndex((cs) => Number(cs.id) === Number(id))
        if (index > -1) this.content.splice(index, 1, payload)
      }
    }
  }
</script>

<template>

  <div class="flex justify-between items-baseline">
    <q-input
      dense outlined
      v-model="searchTerm"
      :label="$t('general.search')"
      debounce="300"
      class="sq-snippets-search q-mb-md"
    />
    <section class="q-mb-md flex">
      <q-btn
        flat dense
        icon-right="add"
        :label="$t('configSnippets.newSnippet')"
        class="app-action-btn q-ml-auto q-pr-sm"
        @click="showSnippetFormDialog = true"
      />
    </section>
  </div>

  <q-card flat>
    <q-card-section>
      <q-table
        hide-bottom
        :rows="filteredContent"
        :columns="columns"
        :rows-per-page-options="[25, 50, 100, 500]"
        :pagination="DEFAULT_PAGINATION"
        row-key="name"
        flat
        class="col-12 transparent q-pa-sm"
      >
        <template #body-cell-actions="{ row }">
          <q-td>
              <span>
                <q-btn
                  dense flat
                  :title="$t('configSnippets.editSnippet')"
                  icon="edit"
                  class="q-mx-xs"
                  @click="handleEditSnippet(row)"
                />
              </span>

            <span>
                <q-btn
                  dense flat
                  :title="$t('configSnippets.viewSnippet')"
                  icon="preview"
                  class="q-mx-xs"
                  @click="handlePreviewSnippet(row)"
                />
            </span>

            <span>
                <q-btn
                  dense flat
                  :title="$t('configSnippets.deleteSnippet')"
                  icon="delete"
                  class="negative-hover q-mx-xs"
                  @click="handleDeleteSnippet(row)"
                />
              </span>
          </q-td>
        </template>
      </q-table>
    </q-card-section>
  </q-card>

  <config-snippet-form-dialog
    v-model="showSnippetFormDialog"
    :config-snippet="selectedConfigSnippet"
    :type="$props.type"
    @created="handleSnippetCreated"
    @updated="handleSnippetUpdated"
    @hide="handleFormDialogClose"
  />

  <config-snippet-preview-dialog
    v-model="showPreviewDialog"
    :config-snippet="selectedConfigSnippet"
    @hide="handlePreviewClose"
  />

  <confirmation-dialog
    v-model="showDeleteConfirmation"
    title="Delete Config Snippet"
    type="negative"
    @confirm="handleConfirmDeleteConfigSnippet"
    @close="closeDeleteSnippetDialog"
  >
    <template #content>
        <span>
          {{ $t('configSnippets.deleteSnippetConfirm') }}
        </span>
    </template>
  </confirmation-dialog>
</template>

<style lang="scss">
 .sq-snippets-tabs {
   margin: 2rem 0 0 0;
 }
 .sq-snippets-search .q-field__inner .q-field__control::before {
   background-color: $background2;
 }
 body.body--dark {
   .sq-snippets-search .q-field__inner .q-field__control::before {
     background-color: $dark-page;
   }
 }
</style>
