<script>
  import { defineComponent, ref } from 'vue'
  import { useStore } from 'vuex'
  import { userManagementService } from 'src/services'
  import SqDialog from 'components/Common/SqDialog.vue'

  export default defineComponent({
    name: 'UserModal',

    components: {
      SqDialog
    },

    emits: [
      'saved'
    ],

    props: {
      page: {},
      itemsPerPage: {},
      user: {}
    },

    setup (props, { emit }) {
      const $store = useStore();

      const userName = ref('')
      const userMail = ref('')
      const userPassword = ref('')
      const userPasswordRepeat = ref('')
      const shouldEmail = ref(false)
      const isLoading = ref(false)
      const isAdmin = ref(false)

      function initialize() {
        if (props.user) {
          userName.value = props.user.displayName ?? props.user.display_name
          userMail.value = props.user.email
          isAdmin.value = !!props.user?.roles?.includes("ROLE_ADMINISTRATOR")
        }
      }

      function saveUser() {
        let userRole = ["ROLE_USER"];
        if (isAdmin.value === true) userRole.push("ROLE_ADMINISTRATOR");
        const formData = {
          name: userName.value,
          email: userMail.value,
          password: userPassword.value,
          roles: userRole,
          shouldEmail: shouldEmail.value
        }

        isLoading.value = true

        if (props.user) {
          userManagementService.updateUser(props.user, formData, (data) => handleResponse(data))
        } else {
          userManagementService.addUser(formData, (data) => handleResponse(data))
        }
      }

      function handleResponse(data) {
        const type = props.user ? 'update' : 'create';
        isLoading.value = false

        if (data?.data['@type'] === "hydra:Error") {
          $store.dispatch('alert/error', "user." + type + "UserFail", { root: true });
        } else {
          $store.dispatch('alert/success', "user." + type + "UserSuccess", {root: true});

          emit('saved')

          resetState()
        }
      }

      function resetState() {
        userName.value = ''
        userMail.value = ''
        userPassword.value = ''
        userPasswordRepeat.value = ''
        shouldEmail.value = false
        isAdmin.value = false
      }

      return {
        isAdmin,
        userName,
        userMail,
        userPassword,
        userPasswordRepeat,
        shouldEmail,
        isLoading,

        pwMinLength: 6,
        isPwd: ref(true),
        isPwd2: ref(true),

        saveUser,
        initialize,
        resetState
      }
    },

    computed: {
      dialogLabel() {
        return this.user ? this.$t('user.updateUser') : this.$t('user.addUser')
      },
      isRequiredFieldEmpty() {
        const alwaysRequired = this.userName === '' || this.userMail === '';
        const pwRequired = this.userPassword === '' || this.userPasswordRepeat === '';
        if(this.$props.user) {
          return alwaysRequired;
        } else {
          return (alwaysRequired || pwRequired);
        }
      },
      pwMatch() {
        return this.userPassword === this.userPasswordRepeat
          &&
          (
              this.userPassword === '' && this.userPasswordRepeat === '' // No userPW update
              ||
              this.userPassword.length >= this.pwMinLength // User PW update - ensure minLength
          );
      },
      existingUser() {
        return !!this.$props.user;
      }
    },

    methods: {
      nameRules (val) {
        if (val.length <= 0) {
          return this.$t('user.form.rules.name.empty');
        }
      },

      mailRules(val) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regex.test(String(val).toLowerCase())) {
          return this.$t('user.form.rules.mail.wrongRegex');
        }
      },

      passwordRules (val) {
        if (val.length < this.pwMinLength && ((this.$props.user && val.length > 0) || !this.$props.user)) {
          return this.$t('user.form.rules.password.tooShort', { minLength: this.pwMinLength });
        }
      },

      passwordRepeatRules (val) {
        if (val !== this.userPassword) {
          return this.$t('user.form.rules.password.repeatDoesNotMatch');
        }
      }
    },

    watch: {

    }
  })
</script>

<template>
  <sq-dialog
    :type="user ? 'update' : 'create'"
    :save-button-label="$t('general.save')"
    :disabled="isRequiredFieldEmpty || !pwMatch"
    :loading="isLoading"
    @save="saveUser"
    @show="initialize"
    @hide="resetState"
  >
    <template #title>
      {{ dialogLabel }}
    </template>

    <template #content>
      <q-form>

        <q-input
          v-model="userName"
          dense
          outlined
          :label="$t('user.form.name')"
          :rules="[nameRules]"
          autocomplete="username"
          class="q-mt-md"
        />

        <q-input
          v-model="userMail"
          dense
          outlined
          :label="$t('user.form.mail')"
          :rules="[mailRules]"
          autocomplete="email"
          class="q-mt-md"
          :readonly="existingUser"
        />

        <q-input
          v-model="userPassword"
          dense
          outlined
          :label="user ? $t('user.form.overwritePassword') : $t('user.form.password')"
          :rules="[passwordRules]"
          :type="isPwd ? 'password' : 'text'"
          autocomplete="new-password"
          class="q-mt-md"
        >
          <template v-slot:prepend>
            <q-icon name="lock" />
          </template>
          <template v-slot:append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            />
            <q-tooltip
              v-if="$q.platform.is.mobile"
              anchor="top middle"
              self="bottom middle"
              class="app-tooltip-mobile"
            >
              {{ isPwd ? $t('general.showPassword') : $t('general.hidePassword') }}
            </q-tooltip>
          </template>
        </q-input>

        <q-input
          v-model="userPasswordRepeat"
          dense
          outlined
          :label="user ? $t('user.form.overwritePasswordRepeat') : $t('user.form.passwordRepeat')"
          :rules="[passwordRules, passwordRepeatRules]"
          :type="isPwd2 ? 'password' : 'text'"
          autocomplete="new-password"
          class="q-mt-md"
        >
          <template v-slot:prepend>
            <q-icon name="lock" />
          </template>
          <template v-slot:append>
            <q-icon
              :name="isPwd2 ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd2 = !isPwd2"
            />
            <q-tooltip
              v-if="$q.platform.is.mobile"
              anchor="top middle"
              self="bottom middle"
              class="app-tooltip-mobile"
            >
              {{ isPwd2 ? $t('general.showPassword') : $t('general.hidePassword') }}
            </q-tooltip>
          </template>
        </q-input>

        <q-toggle
          v-model="isAdmin"
          :label="$t('user.form.role')"
          left-label
        />

        <div v-if="!user">
          <q-toggle
            v-model="shouldEmail"
            :label="$t('user.form.sendEmailNotification')"
            left-label
          />
        </div>
      </q-form>
    </template>
  </sq-dialog>
</template>

<style lang="scss" scoped>
  .app-user-name-input, .app-user-mail-input {
    .q-field__native.q-placeholder {
      padding-left: 1rem;
    }
  }
  .q-btn.disabled {
    opacity: .3 !important;
    &::before {
      box-shadow: none;
    }
  }
</style>
